import { render, staticRenderFns } from "./ProgramTaskComponent.vue?vue&type=template&id=37f2d61e&scoped=true&"
import script from "./ProgramTaskComponent.vue?vue&type=script&lang=ts&"
export * from "./ProgramTaskComponent.vue?vue&type=script&lang=ts&"
import style0 from "./ProgramTaskComponent.less?vue&type=style&index=0&id=37f2d61e&lang=less&scoped=true&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "37f2d61e",
  null
  
)

export default component.exports