import { Vue, Component } from 'vue-property-decorator';
import { ScreenText } from '@/lang/ScreenText';

@Component
export default class TaskComponent extends Vue {
    private objScreenText: ScreenText = new ScreenText();


   
    public getScreenText(key: string): string {
      return this.objScreenText.getScreenText(key);
    }

}