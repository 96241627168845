import { Vue, Component, Prop } from 'vue-property-decorator';
import { ScreenText } from '@/lang/ScreenText';


@Component
export class TaskCreationConfirmation extends Vue {
    private objScreenText: ScreenText = new ScreenText();

    @Prop()
    title! : string;

   
    public getScreenText(key: string): string {
      return this.objScreenText.getScreenText(key);
    }

    closeModal() {
      this.$emit('close', {close: true});
    }

    createTask() {
      this.$emit('create');
    }

}