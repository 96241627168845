import { Vue, Component, Prop } from 'vue-property-decorator';
import VueCal from 'vue-cal';
import { ScreenText } from '@/lang/ScreenText';
import programPlannerStore from '@/store/modules/programPlannerStore';
import APP_CONST from '@/constants/AppConst';
import UIkit from 'uikit';
import APP_UTILITIES from '@/utilities/commonFunctions';
import 'vue-cal/dist/vuecal.css';
import TaskComponent from '@/popupcomponents/taskcomponent/TaskComponent.vue';
import ProgramTaskComponent from '@/popupcomponents/programtaskcomponent/ProgramTaskComponent.vue';
import programPlanFilter from '@/components/programPlanFilter/programPlanFilter.vue';
import programConfigureStore from '@/store/modules/programConfigure';
import TaskCreationConfirmation from '@/popupcomponents/taskcreationconfirmation/TaskCreationConfirmation.vue';
import taskupdateComponent from '@/popupcomponents/taskupdateComponent/taskupdateComponent.vue';
import { IProgramPlanner } from '@/Model/programModel';
import ProgramEventComponent from '@/popupcomponents/programeventcomponent/ProgramEventComponent.vue';
import ReadOnlyTaskComponent from '@/popupcomponents/readonlytaskcomponent/ReadOnlyTaskComponent.vue';
import ReadOnlyEventComponent from '@/popupcomponents/readonlyeventcomponent/ReadOnlyEventComponent.vue';
import StateChangeConfirmation from '@/popupcomponents/statechangeconfirmation/StateChangeConfirmation.vue';
import dashboardStore from '@/store/modules/dashboardStore';
import PrintComponent from '@/components/printcomponent/PrintComponent.vue';
import attendanceStore from '@/store/modules/attendanceStore';
import html2canvas from 'html2canvas';
import jsPDF from 'jspdf';
import printJS from 'print-js';
import './printDoc.css';
import programConfigure from '@/store/modules/programConfigure';


@Component({
  components: {
    'task': TaskComponent,
    'programTask': ProgramTaskComponent,
    'programPlan': programPlanFilter,
    'task-creation-confirm': TaskCreationConfirmation,
    'task-update': taskupdateComponent,
    'programEvent': ProgramEventComponent,
    'readOnlyTask': ReadOnlyTaskComponent,
    'read-only-event': ReadOnlyEventComponent,
    'stateChange': StateChangeConfirmation,
    'vue-calendar': VueCal,
    'PrintComponent': PrintComponent,
  }
})
export default class ProgramPlanner extends Vue {
  readonly STYLE = APP_CONST.STYLE;
  private objScreenText: ScreenText = new ScreenText();
  public plannerList: any = [];
  private getMonths: any = APP_CONST.GET_MONTHS;
  private getMonthIndex: any = APP_CONST.FILTER_TASKS_STRUCTURE;
  private monthNames: any = APP_CONST.MONTH_NAME;
  public currentDate = APP_UTILITIES.getCurrentDate();
  public activeTaskCount: any = 0;
  public doneTaskCount: any = 0;
  public totalActiveTaskCount: any = 0;
  private taskList: any = {};
  private eventList: any = {};
  public temptaskList: any = {};
  public tempEventList: any = {};
  public currentMonth = '';
  public defaultDate: any = '';
  public currentYear: any = '';
  public currentMonthIndex = 0;
  public programId: number = 0;
  public userRoles: any = [];
  public taskTitle: string = '';
  public programData: any = {};
  public taskFilterFlag: boolean = false;
  public taskPrepared: boolean = false;
  public taskCountFalg: boolean = false;
  public currentFilterArr: any[] = [];
  public tasktoUpdate: IProgramPlanner = APP_CONST.PROGRAM_PLANNER as IProgramPlanner;
  public openFilter: boolean = false;
  public resetTKFilter: boolean = true;
  public resetEvntFilter: boolean = false;
  public resetFilter: boolean = false;
  public viewCheckVisible: string = 'normalView';
  public tasksShow: any = [];
  public eventsShow: any = [];
  public menu: boolean = false;
  public EvtMenu: boolean = false;
  public eventToUpdate: any = {};
  public skippedTaskList: any = [];
  public skippedList: boolean = false;
  public totalSkippedTaskCount: number = 0;
  public currentMonthName: string = '';
  public currentYearNumber: number = 0;
  public calendarTasks: any = [];
  public calendarEvents: any = [];
  public calendarViewTasks: string = 'month';
  public calendarViewEvents: string = 'month';
  private tempTaskData: any = '';
  private tempEventData: any = '';
  private startWeekDate: any = '';
  private taskDate: any = {};
  private eventDate: any = {};
  private currentRoleId: number = 0;
  private currentSiteId: number = 0;
  private taskSortedArray: any = [];
  private eventSortedArray: any = [];
  private eventFlag: boolean = false;
  private taskFlag: any = 1;
  private monthLength: any = -1;
  public renderFlag: boolean = false;
  public changableEventList: any = [];
  public eventTabDataLoad: boolean = false;
  filterArr: any = [];
  showCalender: boolean = false;
  public hovering: boolean = false;
  public listIndex: string | null = null;
  changableTaskList: any = [];
  public charactersPerLine: number = 34;
  public startDate: string = '';
  public endDate: string = '';
  public ids = APP_UTILITIES.coreids();
  public isProgAdmin: boolean = false;
  public selectedDate: Date = new Date();
  public userInfoPayload = JSON.parse(JSON.stringify(APP_CONST.USER_INFORMATION));

  @Prop()
  userDetails!: any;

  public deriveMonth = (startDate: string) => {
    if (startDate) {
      return this.getMonths[new Date(startDate).getMonth()].monthName;
    }
  };

  public getScreenText(key: string): string {
    return this.objScreenText.getScreenText(key);
  }

  public getYearFromDate(data: string) {
    const date = new Date(data);
    const year = date.getFullYear();
    return year;
  }

  public convertDateInGMT(date: string) {
    return new Date(date);
  }



  public getMonthDiff(from: string, to: string) {
    const arr = [];
    const datFrom = new Date('1 ' + from);
    const datTo = new Date('1 ' + to);
    const fromYear = datFrom.getFullYear();
    const toYear = datTo.getFullYear();
    const diffYear = (12 * (toYear - fromYear)) + datTo.getMonth();

    for (let i = datFrom.getMonth(); i <= diffYear; i++) {
      arr.push({ month: this.monthNames[i % 12], year: Math.floor(fromYear + (i / 12)) });
    }
    return arr;
  }

  public sortDataByMonth(data: any) {
    return data && data.data.length > 0 && data.data.sort(function (a: any, b: any) {
      const dateA: any = new Date(a.startDate);
      const dateB: any = new Date(b.startDate);
      return dateA - dateB;
    });
  }

  public getProgramId() {
    let pID = 0;
    const userRoles: any = APP_UTILITIES.getCookie('user_role');
    this.userRoles = JSON.parse(userRoles);
    this.userRoles.forEach((item: any, index: number) => {
      if (item.hasOwnProperty('programId')) {
        pID = item.programId;
      }
      else {
        const programId: any = APP_UTILITIES.getCookie('programId');
        pID = Number(programId);
      }

      if (item.hasOwnProperty('roleId')) {
        this.currentRoleId = item.roleId;
      }

    });
    return this.programId = pID;
  }

  public getSiteId() {
    const userRoles: any = APP_UTILITIES.getCookie('user_role');
    this.userRoles = JSON.parse(userRoles);
    this.userRoles.forEach((item: any, index: number) => {
      if (item.hasOwnProperty('siteId')) {
        this.currentSiteId = item.siteId;
      }
      else {
        const programId: any = APP_UTILITIES.getCookie('siteId');
        this.currentSiteId = Number(programId);
      }
    });
  }

  public prepareTaskDataStructure() {
    const pStartMonth = this.deriveMonth(this.eventDate.startDate);
    const pStartYear = this.getYearFromDate(this.eventDate.startDate);
    const pEndMonth = this.deriveMonth(this.eventDate.endDate);
    const pEndYear = this.getYearFromDate(this.eventDate.endDate);
    const startDate = pStartMonth + ' ' + pStartYear;
    const endDate = pEndMonth + ' ' + pEndYear;
    const monthDiff = this.getMonthDiff(startDate, endDate);
    monthDiff.length > 0 && monthDiff.forEach((item: any, index: number) => {
      this.eventList[item.month + ' ' + item.year] = {
        'task': [],
        'startDate': [],
        'month': '',
        'currentDate': '',
        'currentDateCount': 0,
        'totalCount': 0,
        'monthIndex': this.getMonthIndex[item.month].monthIndex + 1,
        'startMonth': item.month,
        'year': item.year,
        'active': [],
        'done': [],
        'totalTask': [],
        'type': 'event'
      };
    });
  }

  public prepareTaskData() {
    const pStartMonth = this.deriveMonth(this.taskDate.startDate);
    const pStartYear = this.getYearFromDate(this.taskDate.startDate);
    const pEndMonth = this.deriveMonth(this.taskDate.endDate);
    const pEndYear = this.getYearFromDate(this.taskDate.endDate);
    const startDate = pStartMonth + ' ' + pStartYear;
    const endDate = pEndMonth + ' ' + pEndYear;
    const monthDiff = this.getMonthDiff(startDate, endDate);
    this.monthLength = monthDiff.length;
    monthDiff.length > 0 && monthDiff.forEach((item: any, index: number) => {
      this.taskList[item.month + ' ' + item.year] = {
        'task': [],
        'startDate': [],
        'month': '',
        'currentDate': '',
        'currentDateCount': 0,
        'totalCount': 0,
        'monthIndex': this.getMonthIndex[item.month].monthIndex + 1,
        'startMonth': item.month,
        'year': item.year,
        'active': [],
        'done': [],
        'type': 'task',
        'totalTask': []
      };
    });
  }

  setDates() {
    if (this.ids.roleId === APP_CONST.PROGRAM_ADMIN_ROLE_ID) {
      this.isProgAdmin = true;
      this.startDate = APP_UTILITIES.formatDate(this.programData.programDto.startDate);
      this.endDate = APP_UTILITIES.formatDate(this.programData.programDto.endDate);
    }
    else if (this.ids.roleId === APP_CONST.SESSION_ADMIN_ROLE_ID) {
      for (const site of this.programData.sitePanels) {
        if (this.currentSiteId === site.siteId) {
          this.startDate = APP_UTILITIES.formatDate(site.startDate);
          this.endDate = APP_UTILITIES.formatDate(site.endDate);
          break;
        }
      }
    }
  }

  mounted() {
    this.userInfoPayload.userId = APP_UTILITIES.getCookie('user_id');
    this.userInfoPayload.programId = APP_UTILITIES.getCookie('programId');
  }
  public beforeMount() {
    this.getSiteId();
    const id: any = this.getProgramId();
    programConfigureStore.getProgramConfigObj(id).then((programData: any) => {
      if (programData && programData.data) {
        this.programData = programData.data;
        this.setDates();
        this.getTaskListByProgram(programData.data, id);
        this.getEventListByProgram(programData.data, id);
      }
    });
  }

  public getEventListByProgram(programData: any, id: number) {
    const args: any = {
      programId: id,
      productId: programConfigure.productId,
      siteId: (this.currentRoleId === 6 || this.currentRoleId === 1 || this.currentRoleId === 2)
        ? this.currentSiteId
        : 0
    };
    programPlannerStore.plannerEventList(args).then((data: any) => {
      this.prepareEventListData(data, programData);
    });
  }

  prepareEventListData(data: any, programData: any) {
    if (data.data.length) {
      if (this.currentRoleId !== APP_CONST.SIX) {
        const sortedArrary = this.sortTask(data.data);
        this.eventSortedArray = sortedArrary;
        this.eventDate.startDate = sortedArrary[0].startDate;
        this.eventDate.endDate = sortedArrary[sortedArrary.length - 1].endDate;

        this.prepareTaskDataStructure();
        this.defaultDate = new Date();
        this.currentYear = new Date().getFullYear();
        this.currentMonth = this.deriveMonth(this.defaultDate);
        this.currentMonthIndex = (this.defaultDate.getMonth() + 1);
        this.taskFilterFlag = true;
        this.prepareEventList(sortedArrary, programData);
      }
      else {
        const eventsList = data.data;
        this.getAttendance(programData, eventsList);
      }

    }
    else {
      const eventsList: any = [];
      this.getAttendance(programData, eventsList);
    }
  }

  private getAttendance(programData: any, eventsList: any) {
    const siteId = this.currentSiteId;
    attendanceStore.getAllHolidays({ siteId: siteId, check: true, programId: this.getProgramId() }).then((holidays: any) => {
      if ((holidays.status === APP_CONST.RESPONSE_200) || (holidays.status === APP_CONST.RESPONSE_204)) {
        holidays.data && holidays.data.forEach((item: any) => {
          item.endDate = item.endDate
            ? item.endDate.split(' ')[APP_CONST.ZERO] + APP_CONST.END_TIME
            : APP_CONST.BLANK;
        });
        const finalList = holidays.data
          ? [...eventsList, ...holidays.data]
          : eventsList;

        const sortedArrary = this.sortTask(finalList);
        this.eventSortedArray = sortedArrary;
        this.eventDate.startDate = sortedArrary[0].startDate;
        this.eventDate.endDate = sortedArrary[sortedArrary.length - 1].endDate;
        this.prepareTaskDataStructure();
        this.defaultDate = new Date();
        this.currentYear = new Date().getFullYear();
        this.currentMonth = this.deriveMonth(this.defaultDate);
        this.currentMonthIndex = (this.defaultDate.getMonth() + 1);
        this.taskFilterFlag = true;
        this.prepareEventList(sortedArrary, programData);
      }
    });
  }

  public convertTo24Hour(time: string) {
    time = time.toUpperCase();
    const hours: any = parseInt(time.substr(0, 2));
    if (time.indexOf('AM') != -1 && hours == 12) {
      time = time.replace('12', '0');
    }
    if (time.indexOf('PM') != -1 && hours < 12) {
      time = time.replace(hours, (hours + 12));
    }
    return time.replace(/(AM|PM)/, '');
  }

  public convertTime24to12(time24h: string) {
    let [hours, minutes]: any = time24h.split(':');
    const ampm = hours >= 12
      ? 'PM'
      : 'AM';
    hours = hours % 12;
    hours = hours
      ? (hours < 10
        ? '0' + hours
        : hours)
      : 12;
    minutes = minutes.length < 2
      ? '0' + minutes
      : minutes;
    const strTime = hours + ':' + minutes + ' ' + ampm;
    return strTime;
  }
  public prepareEventList(sortedArrary: any, programData: any) {
    sortedArrary.length > 0 && sortedArrary.forEach((item: any, index: number) => {
      const startTime = item.startTime
        ? item.startTime
        : item.startDate.split(' ').length > 1
          ? item.startDate.split(' ')[APP_CONST.ONE]
          : '00:00';
      const endTime = item.endTime
        ? item.endTime
        : item.endDate.split(' ').length > 1
          ? item.endDate.split(' ')[APP_CONST.ONE]
          : '01:00';

      const eventObj = {
        'id': item.id,
        'title': item.title || item.name,
        'location': item.location || '',
        'startDate': APP_UTILITIES.formatDate(item.startDate),
        'endDate': item.endDate
          ? APP_UTILITIES.formatDate(item.endDate)
          : '',
        'actualstartDate': item.startDate || '',
        'actualendDate': item.endDate || '',
        'displayDate': APP_UTILITIES.dayFormat(item.startDate),
        'displayMonth': APP_UTILITIES.monthFormat(item.startDate),
        'description': item.description || '',
        'status': {
          'id': item.name
            ? 1
            : item.status.id,
          'state': item.name
            ? 'Active'
            : item.status.state
        },
        'createdBy': item.createdBy || '',
        'createdAt': item.createdAt || '',
        'startMonth': this.deriveMonth(item.startDate),
        'year': this.getYearFromDate(item.startDate),
        'startTime': this.convertTime24to12(startTime),
        'endTime': this.convertTime24to12(endTime),
        'programId': this.getProgramId() || 0,
        'siteId': (this.currentRoleId === 6 || this.currentRoleId === 1 || this.currentRoleId === 2)
          ? this.currentSiteId
          : 0,
        'holiday': item.name
          ? APP_CONST.TRUE
          : APP_CONST.FALSE,
        'startDay': APP_UTILITIES.dayFormat(item.startDate),
        'endDay': APP_UTILITIES.dayFormat(item.endDate),
        'fullMonth': APP_UTILITIES.fullMonthFormat(item.startDate),
        'fullEndMonth': APP_UTILITIES.fullMonthFormat(item.endDate)
      };
      this.preparePlannerData('event', eventObj, programData);
    });

    this.filterTaskByActiveAndDone();
  }

  public sortTask(arr: any) {
    return arr.sort(function (a: any, b: any) {
      return new Date(a.startDate).getTime() - new Date(b.startDate).getTime(); 
    });
  }

  public getTaskListByProgram(programData: any, id: number) {
    const args: any = {
      programId: id,
      productId: programConfigure.productId,
      siteId: (this.currentRoleId === 6 || this.currentRoleId === 1 || this.currentRoleId === 2)
        ? this.currentSiteId
        : 0
    };
    programPlannerStore.plannerList(args).then((data: any) => {
      this.getPlannerListRes(data, programData, id);
    });
  }

  getPlannerListRes(data: any, programData: any, id: number) {
    if (data.data.length) {
      const sortedArrary = this.sortTask(data.data);
      this.taskSortedArray = sortedArrary;
      this.taskDate.startDate = sortedArrary[0].startDate;
      this.taskDate.endDate = sortedArrary[sortedArrary.length - 1].endDate;
      this.prepareTaskData();
      this.defaultDate = new Date();
      this.currentYear = new Date().getFullYear();
      this.currentMonth = this.deriveMonth(this.defaultDate);
      this.currentMonthIndex = (this.defaultDate.getMonth() + 1);
      this.taskFilterFlag = true;
      this.prepareTaskList(sortedArrary, programData);
      this.getEventListByProgram(programData, id);
      this.taskFlag = false;
    }
    else {
      this.monthLength = '';
      this.taskFlag = true;
    }
  }

  public prepareTaskList(sortedArrary: any, programData: any) {
    let i = 0;
    sortedArrary.length > 0 && sortedArrary.forEach((item: any, index: number) => {
      i++;
      const taskObj = {
        'id': item.id,
        'productId': item.productId,
        'productProgramId': item.productProgramId,
        'answerId': item.answerId,
        'cmsTaskId': item.cmsTaskId,
        'title': item.title,
        'startDate': APP_UTILITIES.formatDate(item.startDate),
        'endDate': APP_UTILITIES.formatDate(item.endDate),
        'actualstartDate': item.startDate,
        'actualendDate': item.endDate,
        'description': item.description,
        'isRemoved': item.isRemoved,
        'assignedTo': item.assignedTo,
        'status': {
          'id': item.status.id,
          'state': item.status.state
        },
        'createdBy': item.createdBy,
        'createdAt': item.createdAt,
        'startMonth': this.deriveMonth(item.startDate),
        'year': this.getYearFromDate(item.startDate),
        'programId': this.getProgramId(),
        'siteId': (this.currentRoleId === 6 || this.currentRoleId === 1 || this.currentRoleId === 2)
          ? this.currentSiteId
          : 0,
        'resources': item.resources || [],
        'checkLists': item.checkLists || []
      };
      this.preparePlannerData('task', taskObj, programData);

    });
    this.filterTaskByActiveAndDone();
  }

  public filterTaskByActiveAndDone() {
    if (this.taskList && this.eventList) {
      if ((this.monthLength === Object.keys(this.taskList).length) && (!this.renderFlag)) {
        this.renderFlag = true;
        this.taskPrepared = true;
        this.temptaskList = { ...this.taskList };
        this.tempEventList = { ...this.eventList };
      }
      this.showTaskEventPanel();

    }
  }

  showTaskEventPanel() {
    if (this.$route.query.type === 'event') {
      const evId: any = this.$route.query.eId;
      for (const month in this.tempEventList) {
        this.tempEventList[month].task.forEach((item: any, index: number) => {
          if (parseInt(item.id) === parseInt(evId)) {
            this.updateEventProp(item);
          }
        });
      }
    }
    else if (this.$route.query.type === 'task') {
      const tkId: any = this.$route.query.tId;
      for (const month in this.temptaskList) {
        this.temptaskList[month].task.forEach((item: any, index: number) => {
          if (parseInt(item.id) === parseInt(tkId)) {
            this.updateProp(item);
          }
        });
      }
    }
  }

  getFullYear(date: any) {
    return new Date(date).getFullYear();
  }

  prepareTaskEventKey(startDate: any) {
    return this.deriveMonth(startDate) + ' ' + this.getFullYear(startDate);
  }


  public preparePlannerData(from: string, item: any, programData: any) {
    if (item) {
      if (from === 'task') {
        for (const monthName in this.taskList) {
          if (monthName !== undefined) {
            if (monthName === this.prepareTaskEventKey(item.startDate)) {

              if (!item.isRemoved) {
                this.taskList[this.prepareTaskEventKey(item.startDate)].task.push(item);
                this.taskList[this.prepareTaskEventKey(item.startDate)].totalTask.push(item);
                this.taskList[this.prepareTaskEventKey(item.startDate)].startDate.push(item.startDate);
                this.taskList[this.prepareTaskEventKey(item.startDate)].month = this.deriveMonth(item.startDate);
                this.taskList[this.prepareTaskEventKey(item.startDate)].currentDate = this.currentDate;
                if ((this.currentDate === item.startDate)) {
                  this.taskList[this.prepareTaskEventKey(item.startDate)].currentDateCount += 1;
                }
                if ((this.convertDateInGMT(this.currentDate) < this.convertDateInGMT(item.startDate)) && (item.status.state === 'Active')) {
                  this.taskList[this.prepareTaskEventKey(item.startDate)].totalCount += 1;
                }
              }
              else if (item.isRemoved) {
                this.skippedTaskList.push(item);
              }

            }
          }
        }
      }
      else if (from === 'event') {
        for (const monthName in this.eventList) {
          if (monthName !== undefined) {
            if (monthName === this.prepareTaskEventKey(item.startDate)) {
              this.eventList[this.prepareTaskEventKey(item.startDate)].task.push(item);
              this.eventList[this.prepareTaskEventKey(item.startDate)].totalTask.push(item);
              this.eventList[this.prepareTaskEventKey(item.startDate)].startDate.push(item.startDate);
              this.eventList[this.prepareTaskEventKey(item.startDate)].month = this.deriveMonth(item.startDate);
              this.eventList[this.prepareTaskEventKey(item.startDate)].currentDate = this.currentDate;

              if ((this.currentDate === item.startDate)) {
                this.eventList[this.prepareTaskEventKey(item.startDate)].currentDateCount += 1;
              }
              if ((this.convertDateInGMT(this.currentDate) < this.convertDateInGMT(item.startDate)) && (item.status.state === 'Active')) {
                this.eventList[this.prepareTaskEventKey(item.startDate)].totalCount += 1;
              }
            }
          }
        }
      }
    }
  }

  public sortMonth(filterArr: any) {
    return filterArr.sort(function (a: any, b: any) {
      return APP_CONST.MONTH_NAME.indexOf(a.month) - APP_CONST.MONTH_NAME.indexOf(b.month);
    });
  }

  public skippedCount(count: number) {
    this.totalSkippedTaskCount = count;
  }

  public showSkippedList(e: any) {
    if (e === true) {
      this.skippedList = e;
    }
    else if (e === false) {
      this.skippedList = e;
    }
  }

  public prepareTaskLocalInstance() {
    const localTaskStructure: any = {};
    const pStartMonth = this.deriveMonth(this.taskDate.startDate);
    const pStartYear = this.getYearFromDate(this.taskDate.startDate);
    const pEndMonth = this.deriveMonth(this.taskDate.endDate);
    const pEndYear = this.getYearFromDate(this.taskDate.endDate);
    const startDate = pStartMonth + ' ' + pStartYear;
    const endDate = pEndMonth + ' ' + pEndYear;
    const monthDiff = this.getMonthDiff(startDate, endDate);
    monthDiff.length > 0 && monthDiff.forEach((item: any, index: number) => {
      localTaskStructure[item.month + ' ' + item.year] = {
        'task': [],
        'startDate': [],
        'month': '',
        'currentDate': '',
        'currentDateCount': 0,
        'totalCount': 0,
        'monthIndex': this.getMonthIndex[item.month].monthIndex + 1,
        'startMonth': item.month,
        'year': item.year,
        'active': [],
        'done': [],
        'type': 'task',
        'totalTask': []
      };
    });
    return localTaskStructure;

  }

  public prepareEventLocalInstance() {
    const localEventStructure: any = {};
    const pStartMonth = this.deriveMonth(this.eventDate.startDate);
    const pStartYear = this.getYearFromDate(this.eventDate.startDate);
    const pEndMonth = this.deriveMonth(this.eventDate.endDate);
    const pEndYear = this.getYearFromDate(this.eventDate.endDate);
    const startDate = pStartMonth + ' ' + pStartYear;
    const endDate = pEndMonth + ' ' + pEndYear;
    const monthDiff = this.getMonthDiff(startDate, endDate);
    monthDiff.length > 0 && monthDiff.forEach((item: any, index: number) => {
      localEventStructure[item.month + ' ' + item.year] = {
        'task': [],
        'startDate': [],
        'month': '',
        'currentDate': '',
        'currentDateCount': 0,
        'totalCount': 0,
        'monthIndex': this.getMonthIndex[item.month].monthIndex + 1,
        'startMonth': item.month,
        'year': item.year,
        'active': [],
        'done': [],
        'type': 'event',
        'totalTask': []
      };
    });
    return localEventStructure;
  }

  public itemToFilter(itemArr: any) {
    const newItemArr = [...itemArr];
    const filterArr: any = this.sortMonth(itemArr);
    this.currentFilterArr = filterArr;
    this.temptaskList = {};
    this.tempEventList = {};
    if (!filterArr.length) {
      if (this.resetEvntFilter) {
        this.tempEventList = { ...this.eventList };
      }
      else {
        this.temptaskList = { ...this.taskList };
        this.getFilteredActiveTaskCount();

      }
    }
    else if (filterArr.length > 0) {
      if (this.resetEvntFilter) {
        const localInstance = this.prepareEventLocalInstance();
        const prepareTaskData: any = localInstance;
        this.prepareEvents(filterArr, prepareTaskData);
        this.getFilteredActiveEventCount();
      }
      else {
        const localInstance = this.prepareTaskLocalInstance();
        const prepareTaskData: any = JSON.parse(JSON.stringify(localInstance));
        this.prepareTasks(filterArr, prepareTaskData);
        this.getFilteredActiveTaskCount();
      }
    }
    this.changeListView('onAddEvent', newItemArr);
    this.resetFilter = false;
    this.filterArr = newItemArr;
  }

  public prepareEvents(filterArr: any, prepareTaskData: any) {
    filterArr.forEach((item: any, index: number) => {
      for (const tempTask in this.eventList) {
        prepareTaskData[tempTask]['month'] = this.eventList[tempTask].month;
        prepareTaskData[tempTask]['currentDate'] = this.eventList[tempTask].currentDate;
        prepareTaskData[tempTask]['currentDateCount'] = this.eventList[tempTask].currentDateCount;
        prepareTaskData[tempTask]['totalCount'] = this.eventList[tempTask].totalCount;
        prepareTaskData[tempTask]['monthIndex'] = this.eventList[tempTask].monthIndex;
        if ((tempTask === (item.month + ' ' + item.year) && item.active === '' && item.done === '')) {
          this.eventList[tempTask].task.forEach((singleTask: any, i: number) => {
            prepareTaskData[tempTask].task.push(singleTask);
            prepareTaskData[tempTask].done.push(singleTask);
            prepareTaskData[tempTask].active.push(singleTask);
          });
        }
        if (tempTask === (item.month + ' ' + item.year) && item.active === 'Active' && item.done === '') {
          this.eventList[tempTask].task.forEach((singleTask: any, i: number) => {
            if (singleTask.status.state === item.active) {
              prepareTaskData[tempTask].task.push(singleTask);
              prepareTaskData[tempTask].active.push(singleTask);
            }
            else {
              prepareTaskData[tempTask].done.push(singleTask);
            }
          });
        }
        if ((tempTask === (item.month + ' ' + item.year) && item.active === '' && item.done === 'Done')) {
          this.eventList[tempTask].task.forEach((singleTask: any, i: number) => {
            if (singleTask.status.state === item.done) {
              prepareTaskData[tempTask].task.push(singleTask);
              prepareTaskData[tempTask].done.push(singleTask);
            }
            else {
              prepareTaskData[tempTask].active.push(singleTask);
            }
          });
        }
        else if ((item.month === '' && item.active === 'Active' && item.done === 'Done')) {
          this.eventList[tempTask].task.forEach((singleTask: any, i: number) => {
            if (singleTask.status.state === item.active) {
              prepareTaskData[tempTask].task.push(singleTask);
              prepareTaskData[tempTask].active.push(singleTask);
            }
            else if (singleTask.status.state === item.done) {
              prepareTaskData[tempTask].task.push(singleTask);
              prepareTaskData[tempTask].done.push(singleTask);
            }
          });
        }
        else if (((item.month + ' ' + item.year) === tempTask && item.active === 'Active' && item.done === 'Done')) {
          this.eventList[tempTask].task.forEach((singleTask: any, i: number) => {
            if (singleTask.status.state === item.active) {
              prepareTaskData[tempTask].task.push(singleTask);
              prepareTaskData[tempTask].active.push(singleTask);
            }
            if (singleTask.status.state === item.done) {
              prepareTaskData[tempTask].task.push(singleTask);
              prepareTaskData[tempTask].done.push(singleTask);
            }
          });
        }
        if ((item.month === '' && item.active === '' && item.done === 'Done')) {
          this.eventList[tempTask].task.forEach((singleTask: any, i: number) => {
            if (singleTask.status.state === item.done) {
              prepareTaskData[tempTask].task.push(singleTask);
              prepareTaskData[tempTask].done.push(singleTask);
            }
            else {
              prepareTaskData[tempTask].active.push(singleTask);
            }
          });
        }
        if ((item.month === '' && item.active === 'Active' && item.done === '')) {
          this.eventList[tempTask].task.forEach((singleTask: any, i: number) => {
            if (singleTask.status.state === item.active) {
              prepareTaskData[tempTask].task.push(singleTask);
              prepareTaskData[tempTask].active.push(singleTask);
            }
            else {
              prepareTaskData[tempTask].done.push(singleTask);
            }
          });
        }
      }
    });
    this.taskFilterFlag = true;
    this.tempEventList = { ...prepareTaskData };
    this.renderFinalEventData(filterArr);
  }

  public prepareTasks(filterArr: any, prepareTaskData: any) {
    filterArr.forEach((item: any, index: number) => {
      for (const tempTask in this.taskList) {
        prepareTaskData[tempTask]['startDate'] = this.taskList[tempTask].startDate;
        prepareTaskData[tempTask]['month'] = this.taskList[tempTask].month;
        prepareTaskData[tempTask]['currentDate'] = this.taskList[tempTask].currentDate;
        prepareTaskData[tempTask]['currentDateCount'] = this.taskList[tempTask].currentDateCount;
        prepareTaskData[tempTask]['totalCount'] = this.taskList[tempTask].totalCount;
        prepareTaskData[tempTask]['monthIndex'] = this.taskList[tempTask].monthIndex;
        if ((tempTask === item.month + ' ' + item.year && item.active === '' && item.done === '')) {
          this.taskList[tempTask].task.length > 0 && this.taskList[tempTask].task.forEach((singleTask: any, i: number) => {
            prepareTaskData[tempTask].task.push(singleTask);
            prepareTaskData[tempTask].done.push(singleTask);
            prepareTaskData[tempTask].active.push(singleTask);
          });
        }
        if (tempTask === item.month + ' ' + item.year && item.active === 'Active' && item.done === '') {
          this.taskList[tempTask].task.length > 0 && this.taskList[tempTask].task.forEach((singleTask: any, i: number) => {
            if (singleTask.status.state === item.active) {
              prepareTaskData[tempTask].task.push(singleTask);
              prepareTaskData[tempTask].active.push(singleTask);
            }
            else {
              prepareTaskData[tempTask].done.push(singleTask);
            }
          });
        }
        if ((tempTask === item.month + ' ' + item.year && item.active === '' && item.done === 'Done')) {
          this.taskList[tempTask].task.length > 0 && this.taskList[tempTask].task.forEach((singleTask: any, i: number) => {
            if (singleTask.status.state === item.done) {
              prepareTaskData[tempTask].task.push(singleTask);
              prepareTaskData[tempTask].done.push(singleTask);
            }
            else {
              prepareTaskData[tempTask].active.push(singleTask);
            }
          });
        }
        else if ((item.month === '' && item.active === 'Active' && item.done === 'Done')) {
          this.taskList[tempTask].task.length > 0 && this.taskList[tempTask].task.forEach((singleTask: any, i: number) => {
            if (singleTask.status.state === item.active) {
              prepareTaskData[tempTask].task.push(singleTask);
              prepareTaskData[tempTask].active.push(singleTask);
            }
            else if (singleTask.status.state === item.done) {
              prepareTaskData[tempTask].task.push(singleTask);
              prepareTaskData[tempTask].done.push(singleTask);
            }
          });
        }
        else if ((item.month + ' ' + item.year === tempTask && item.active === 'Active' && item.done === 'Done')) {
          this.taskList[tempTask].task.length > 0 && this.taskList[tempTask].task.forEach((singleTask: any, i: number) => {
            if (singleTask.status.state === item.active) {
              prepareTaskData[tempTask].task.push(singleTask);
              prepareTaskData[tempTask].active.push(singleTask);
            }
            if (singleTask.status.state === item.done) {
              prepareTaskData[tempTask].task.push(singleTask);
              prepareTaskData[tempTask].done.push(singleTask);
            }
          });
        }
        if ((item.month === '' && item.active === '' && item.done === 'Done')) {
          this.taskList[tempTask].task.length > 0 && this.taskList[tempTask].task.forEach((singleTask: any, i: number) => {
            if (singleTask.status.state === item.done) {
              prepareTaskData[tempTask].task.push(singleTask);
              prepareTaskData[tempTask].done.push(singleTask);
            }
            else {
              prepareTaskData[tempTask].active.push(singleTask);
            }
          });
        }
        if ((item.month === '' && item.active === 'Active' && item.done === '')) {
          this.taskList[tempTask].task.length > 0 && this.taskList[tempTask].task.forEach((singleTask: any, i: number) => {
            if (singleTask.status.state === item.active) {
              prepareTaskData[tempTask].task.push(singleTask);
              prepareTaskData[tempTask].active.push(singleTask);
            }
            else {
              prepareTaskData[tempTask].done.push(singleTask);
            }
          });
        }
      }
    });
    this.taskFilterFlag = true;
    this.temptaskList = { ...prepareTaskData };
    this.renderFinalTaskTData(filterArr);
    this.getCurrentDayActiveDoneCount();
  }

  public renderFinalTaskTData(filterArr: any) {
    let filterFlag = false;
    const temData: any = {};
    filterArr.forEach((item: any, index: number) => {
      for (const month in this.temptaskList) {
        if ((item.month + ' ' + item.year === month && item.active === 'Active' && item.done === '')) {
          filterFlag = true;
          temData[month] = this.temptaskList[month];
        }
        if ((item.month + ' ' + item.year === month && item.done === 'Done' && item.active === '')) {
          filterFlag = true;
          temData[month] = this.temptaskList[month];
        }
        if ((item.month + ' ' + item.year === month && item.done === 'Done' && item.active === 'Active')) {
          filterFlag = true;
          temData[month] = this.temptaskList[month];
        }
        if ((item.month + ' ' + item.year === month && item.done === '' && item.active === '')) {
          filterFlag = true;
          temData[month] = this.temptaskList[month];
        }
      }
    });


    if (filterFlag) {
      this.taskPrepared = false;
      this.temptaskList = { ...temData };
    }

  }

  public renderFinalEventData(filterArr: any) {
    let filterFlag = false;
    const temData: any = {};
    filterArr.forEach((item: any, index: number) => {
      for (const month in this.tempEventList) {
        if ((item.month + ' ' + item.year === month && item.active === 'Active' && item.done === '')) {
          filterFlag = true;
          temData[month] = this.tempEventList[month];
        }
        if ((item.month + ' ' + item.year === month && item.done === 'Done' && item.active === '')) {
          filterFlag = true;
          temData[month] = this.tempEventList[month];
        }
        if ((item.month + ' ' + item.year === month && item.done === 'Done' && item.active === 'Active')) {
          filterFlag = true;
          temData[month] = this.tempEventList[month];
        }
        if ((item.month + ' ' + item.year === month && item.done === '' && item.active === '')) {
          filterFlag = true;
          temData[month] = this.tempEventList[month];
        }
      }
    });
    if (filterFlag) {
      this.taskPrepared = false;
      this.tempEventList = { ...temData };
    }
  }

  public getFilteredActiveEventCount() {
    this.activeTaskCount = 0;
    this.doneTaskCount = 0;
    this.totalActiveTaskCount = 0;
    if (this.currentFilterArr.length > 0) {
      for (const monthName in this.tempEventList) {
        this.tempEventList[monthName].task.forEach((item: any, index: number) => {
          if (item.status.state === APP_CONST.ACTIVE_STATUS) {
            this.activeTaskCount += 1;
          }
        });
        this.tempEventList[monthName].active.forEach((item: any, index: number) => {
          if (item.status.state === APP_CONST.ACTIVE_STATUS) {
            this.totalActiveTaskCount += 1;
          }
        });
        this.tempEventList[monthName].done.forEach((item: any, index: number) => {
          if (item.status.state === APP_CONST.DONE_STATUS) {
            this.doneTaskCount += 1;
          }
        });
      }
    }
    else {
      for (const monthName in this.tempEventList) {
        this.tempEventList[monthName].task.forEach((item: any, index: number) => {
          if (item.status.state === APP_CONST.ACTIVE_STATUS) {
            this.activeTaskCount += 1;
            this.totalActiveTaskCount += 1;
          }
          else {
            this.doneTaskCount += 1;
          }
        });
      }
    }
  }
  public getCurrentMonthAndYear() {
    const months = ['January', 'February', 'March', 'April', 'May', 'June', 'July', 'August', 'September', 'October', 'November', 'December'];
    const date = new Date();
    return months[date.getMonth()] + ' ' + date.getFullYear();
  }

  getCurrentDayActiveDoneCount() {
    let monthName = this.getCurrentMonthAndYear();
    if (this.currentFilterArr.length > 0) {
      this.currentFilterArr.forEach((keys: any, filterIndex: number) => {
        if (keys && keys.month === '' && keys.year === '') {
          monthName = this.getCurrentMonthAndYear();
        }
        else {
          monthName = keys.month + ' ' + keys.year;
        }
        if (keys.month + ' ' + keys.year === monthName) {
          if (monthName != '') {
            if (Object.keys(this.temptaskList).length > 0) {
              this.temptaskList[monthName].currentDateCount = 0;
            }
          }
          if (monthName === keys.month + ' ' + keys.year && keys.active === 'Active' && keys.done === '') {
            this.temptaskList[monthName].task.length > 0 && this.temptaskList[monthName].task.forEach((item: any, index: number) => {
              if (keys.active === APP_CONST.ACTIVE_STATUS) {
                if ((this.currentDate === item.startDate) && (item.status.state === APP_CONST.ACTIVE_STATUS)) {
                  this.temptaskList[monthName].currentDateCount += 1;
                }
              }
            });
          }

          if (monthName === keys.month + ' ' + keys.year && keys.active === '' && keys.done === 'Done') {
            this.temptaskList[monthName].done.length > 0 && this.temptaskList[monthName].done.forEach((item: any, index: number) => {
              if (keys.done === APP_CONST.DONE_STATUS) {
                if ((this.currentDate === item.startDate) && (item.status.state === APP_CONST.DONE_STATUS)) {
                  this.temptaskList[monthName].currentDateCount += 1;
                }
              }
            });
          }

          if (monthName === keys.month + ' ' + keys.year && keys.active === '' && keys.done === '') {
            this.temptaskList[monthName].task.length > 0 && this.temptaskList[monthName].task.forEach((item: any, index: number) => {
              if ((this.currentDate === item.startDate) && (item.status.state === APP_CONST.ACTIVE_STATUS)) {
                this.temptaskList[monthName].currentDateCount += 1;
              }
            });

            this.temptaskList[monthName].done.length > 0 && this.temptaskList[monthName].done.forEach((item: any, index: number) => {
              if ((this.currentDate === item.startDate) && (item.status.state === APP_CONST.DONE_STATUS)) {
                this.temptaskList[monthName].currentDateCount += 1;
              }
            });
          }

          if (keys.month === '' && keys.active === 'Active' && keys.done === 'Done') {
            this.temptaskList[monthName].task.length > 0 && this.temptaskList[monthName].task.forEach((item: any, index: number) => {
              if (keys.active === APP_CONST.ACTIVE_STATUS) {
                if ((this.currentDate === item.startDate) && (item.status.state === APP_CONST.ACTIVE_STATUS)) {
                  this.temptaskList[monthName].currentDateCount += 1;
                }
              }
            });

            this.temptaskList[monthName].done.length > 0 && this.temptaskList[monthName].done.forEach((item: any, index: number) => {
              if (keys.done === APP_CONST.DONE_STATUS) {
                if ((this.currentDate === item.startDate) && (item.status.state === APP_CONST.DONE_STATUS)) {
                  this.temptaskList[monthName].currentDateCount += 1;
                }
              }
            });
          }

          if (keys.month + ' ' + keys.year === monthName && keys.active === 'Active' && keys.done === 'Done') {
            this.temptaskList[monthName].task.length > 0 && this.temptaskList[monthName].task.forEach((item: any, index: number) => {
              if (keys.active === APP_CONST.ACTIVE_STATUS) {
                if ((this.currentDate === item.startDate) && (item.status.state === APP_CONST.ACTIVE_STATUS)) {
                  this.temptaskList[monthName].currentDateCount += 1;
                }
              }
            });

            this.temptaskList[monthName].done.length > 0 && this.temptaskList[monthName].done.forEach((item: any, index: number) => {
              if (keys.done === APP_CONST.DONE_STATUS) {
                if ((this.currentDate === item.startDate) && (item.status.state === APP_CONST.DONE_STATUS)) {
                  this.temptaskList[monthName].currentDateCount += 1;
                }
              }
            });
          }
          if (keys.month === '' && keys.active === '' && keys.done === 'Done') {
            this.temptaskList[monthName].done.length > 0 && this.temptaskList[monthName].done.forEach((item: any, index: number) => {
              if (keys.done === APP_CONST.DONE_STATUS) {
                if ((this.currentDate === item.startDate) && (item.status.state === APP_CONST.DONE_STATUS)) {
                  this.temptaskList[monthName].currentDateCount += 1;
                }
              }
            });
          }

          if (keys.month === '' && keys.active === 'Active' && keys.done === '') {
            this.temptaskList[monthName].task.length > 0 && this.temptaskList[monthName].task.forEach((item: any, index: number) => {
              if (keys.active === APP_CONST.ACTIVE_STATUS) {
                if ((this.currentDate === item.startDate) && (item.status.state === APP_CONST.ACTIVE_STATUS)) {
                  this.temptaskList[monthName].currentDateCount += 1;
                }
              }
            });
          }
        }
        else if (keys.month + ' ' + keys.year !== monthName) {
          if (keys.month === '' && keys.active === 'Active' && keys.done === 'Done') {
            if ((Object.keys(this.temptaskList).length > 0) && (Object.keys(this.temptaskList).indexOf(monthName) != -1)) {
              this.temptaskList[monthName].currentDateCount = 0;
              this.temptaskList[monthName].task.length > 0 && this.temptaskList[monthName].task.forEach((item: any, index: number) => {
                if (keys.active === APP_CONST.ACTIVE_STATUS) {
                  if ((this.currentDate === item.startDate) && (item.status.state === APP_CONST.ACTIVE_STATUS)) {
                    this.temptaskList[monthName].currentDateCount += 1;
                  }
                }
              });
              this.temptaskList[monthName].done.length > 0 && this.temptaskList[monthName].done.forEach((item: any, index: number) => {
                if (keys.done === APP_CONST.DONE_STATUS) {
                  if ((this.currentDate === item.startDate) && (item.status.state === APP_CONST.DONE_STATUS)) {
                    this.temptaskList[monthName].currentDateCount += 1;
                  }
                }
              });
            }

          }

          if (keys.month === '' && keys.active === '' && keys.done === 'Done') {
            if ((Object.keys(this.temptaskList).length > 0) && (Object.keys(this.temptaskList).indexOf(monthName) != -1)) {
              this.temptaskList[monthName].currentDateCount = 0;
              this.temptaskList[monthName].done.length > 0 && this.temptaskList[monthName].done.forEach((item: any, index: number) => {
                if (keys.done === APP_CONST.DONE_STATUS) {
                  if ((this.currentDate === item.startDate) && (item.status.state === APP_CONST.DONE_STATUS)) {
                    this.temptaskList[monthName].currentDateCount += 1;
                  }
                }
              });
            }
          }
          if (keys.month === '' && keys.active === 'Active' && keys.done === '') {
            if ((Object.keys(this.temptaskList).length > 0) && (Object.keys(this.temptaskList).indexOf(monthName) != -1)) {
              this.temptaskList[monthName].currentDateCount = 0;
              this.temptaskList[monthName].task.length > 0 && this.temptaskList[monthName].task.forEach((item: any, index: number) => {
                if (keys.active === APP_CONST.ACTIVE_STATUS) {
                  if ((this.currentDate === item.startDate) && (item.status.state === APP_CONST.ACTIVE_STATUS)) {
                    this.temptaskList[monthName].currentDateCount += 1;
                  }
                }
              });
            }
          }
        }
      });
    }
  }

  public getFilteredActiveTaskCount() {
    this.activeTaskCount = 0;
    this.doneTaskCount = 0;
    this.totalActiveTaskCount = 0;
    if (this.currentFilterArr.length > 0) {
      for (const monthName in this.temptaskList) {
        this.temptaskList[monthName].task.forEach((item: any, index: number) => {
          if (item.status.state === APP_CONST.ACTIVE_STATUS) {
            this.activeTaskCount += 1;
          }
        });
        this.temptaskList[monthName].active.forEach((item: any, index: number) => {
          if (item.status.state === APP_CONST.ACTIVE_STATUS) {
            this.totalActiveTaskCount += 1;

          }
        });
        this.temptaskList[monthName].done.forEach((item: any, index: number) => {
          if (item.status.state === APP_CONST.DONE_STATUS) {
            this.doneTaskCount += 1;
          }
        });
      }
    }
    else {
      for (const monthName in this.temptaskList) {
        this.temptaskList[monthName].task.forEach((item: any, index: number) => {
          if (item.status.state === APP_CONST.ACTIVE_STATUS) {
            this.activeTaskCount += 1;
            this.totalActiveTaskCount += 1;
          }
          else {
            this.doneTaskCount += 1;
          }
        });
      }
    }


  }


  public filterTask() {

  }

  public formatDate(date: string) {
    let d = new Date(date),
      month = '' + (d.getMonth() + 1),
      day = '' + d.getDate(),
      year = d.getFullYear();

    if (month.length < 2) {
      month = '0' + month;
    }
    if (day.length < 2) {
      day = '0' + day;
    }

    return [year, month, day].join('-');
  }

  public changeListView(typeView: string, filterArr: any = []) {
    this.calendarTasks = [];
    this.tasksShow = [];
    this.calendarEvents = [];
    this.eventsShow = [];
    let selectedStatus = false;
    if (typeView === this.viewCheckVisible) {
      selectedStatus = true;
    }
    typeView !== 'onAddEvent' && (this.viewCheckVisible = typeView);
    Object.keys({ ...this.temptaskList }).forEach((task: any, taskIndex: number) => {
      if (this.temptaskList[task].task.length > 0) {
        this.temptaskList[task].task.forEach((item: any, itemIndex: number) => {
          this.calendarTasks.push({ ...item, taskListMonth: task, taskListIndex: taskIndex, taskIndex: itemIndex, type: 'taskType' });
          const eventObj = {
            start: this.formatDate(item.startDate),
            end: this.formatDate(item.endDate),
            title: item.title,
            content: item.description,
            class: 'health',
            background: true,
            taskListMonth: task,
            taskIndex: itemIndex,
            type: 'taskType'
          };
          this.tasksShow.push(eventObj);
        });
      }
      if (this.viewCheckVisible === 'calendar' && taskIndex === Object.keys(this.temptaskList).length - 1) {
        let monthNameGet = task.split(' ')[0];
        let monthNameIndex = 0;
        let year = 0;
        APP_CONST.GET_MONTHS.forEach((newMonth: any) => {
          if (monthNameGet === newMonth.monthName) {
            monthNameIndex = new Date().getMonth();
            year = new Date().getFullYear();
          }
        });

        if (filterArr.length > 0 && filterArr[filterArr.length - 1].month !== '') {
          monthNameGet = filterArr[filterArr.length - 1].month;
          year = filterArr[filterArr.length - 1].year;
          APP_CONST.GET_MONTHS.forEach((newMonth: any) => {
            if (monthNameGet === newMonth.monthName) {
              monthNameIndex = newMonth.index;
            }
          });
        }

        setTimeout(() => {
          const d = new Date();
          d.setMonth(monthNameIndex);
          d.setFullYear(year);
          const newRef = this.$refs.valcaltasks as any;
          newRef && newRef.switchView('month', new Date(d));
          this.showCalender = false;
        }, 0)
        ;
      }
    });
    this.changableTaskList = JSON.parse(JSON.stringify(this.tasksShow));
    this.changeTaskMonthView();
    Object.keys({ ...this.tempEventList }).forEach((task: any, taskIndex: number) => {
      if (this.tempEventList[task].task.length > 0) {
        this.tempEventList[task].task.forEach((item: any, itemIndex: number) => {
          this.calendarEvents.push({ ...item, taskListMonth: task, taskListIndex: taskIndex, taskIndex: itemIndex, type: 'eventType' });
          const updateStartDateCheck = item.startDate && (item.startDate.split(':')[APP_CONST.ONE]
            ? item.startDate.split(':')[APP_CONST.ONE].length > 0
            : false);
          const updateEndDateCheck = item.endDate && (item.endDate.split(':')[APP_CONST.ONE]
            ? item.endDate.split(':')[APP_CONST.ONE].length > 0
            : false);
          let eventTitle = '';
          if (item.title.length >= 15) {
            let slicedTitle = item.title.substring(0, 15);
            slicedTitle = slicedTitle.slice(0, -3);
            eventTitle = slicedTitle.trim() + '...';
          }
          else {
            eventTitle = item.title;
          }
          const eventObj = {
            start: updateStartDateCheck
              ? item.startDate
              : (item.startDate + ' ' + (item.startTime
                ? item.startTime
                : '00:00')),
            end: updateEndDateCheck
              ? item.endDate
              : (item.endDate + ' ' + (item.endTime
                ? item.endTime
                : '01:00')),
            title: eventTitle,
            content: item.description,
            class: 'health' + (item.holiday
              ? ' holiday'
              : ''),
            background: true,
            taskListMonth: task,
            taskIndex: itemIndex,
            type: 'eventType',
            holiday: item.holiday
          };
          this.eventsShow.push(eventObj);
        });
      }
      if (this.viewCheckVisible === 'calendar' && taskIndex === Object.keys(this.tempEventList).length - 1) {
        let monthNameGet = task.split(' ')[0];
        let monthNameIndex = 0;
        let year = 0;
        APP_CONST.GET_MONTHS.forEach((newMonth: any) => {
          if (monthNameGet === newMonth.monthName) {
            monthNameIndex = new Date().getMonth();
            year = new Date().getFullYear();
          }
        });
        if (filterArr.length > 0 && filterArr[filterArr.length - 1].month !== '') {
          monthNameGet = filterArr[filterArr.length - 1].month;
          year = filterArr[filterArr.length - 1].year;
          APP_CONST.GET_MONTHS.forEach((newMonth: any) => {
            if (monthNameGet === newMonth.monthName) {
              monthNameIndex = newMonth.index;
            }
          });
        }
        setTimeout(() => {
          const d = new Date();
          d.setMonth(monthNameIndex);
          d.setFullYear(year);
          const newRef = this.$refs.valcalevents as any;
          newRef && newRef.switchView('month', new Date(d));
          this.showCalender = false;
        }, 0);
      }
    });
    if (!selectedStatus) {
      this.setDefaultTaskToActive();
    }
    this.changableEventList = JSON.parse(JSON.stringify(this.eventsShow));
    if (typeView == 'onAddEvent') {
      this.changeCalMonthView();
    }
  }

  changeTaskMonthView() {
    const tempTaskList = JSON.parse(JSON.stringify(this.changableTaskList));
    const list: any = [];
    tempTaskList.forEach((item: any) => {
      const differenceinTime: any = new Date(item.start).getTime() - new Date(item.end).getTime();
      const differenceinDays: any = differenceinTime / (1000 * 3600 * 24);
      if ((differenceinDays >= 1) || (differenceinDays <= -1)) {
        list.push({ ...item, end: item.start, class: 'health task start' });
        list.push({ ...item, start: item.end, class: 'health task end' });
      }
      else {
        list.push({ ...item, class: 'health task start end' });
      }
    });
    this.tasksShow = JSON.parse(JSON.stringify(list));
  }


  changeCalMonthView() {
    const tempEventList = JSON.parse(JSON.stringify(this.changableEventList));
    const list: any = [];
    tempEventList.forEach((item: any) => {
      if (item.holiday) {
        const differenceinTime: any = new Date(item.start).getTime() - new Date(item.end).getTime();
        const differenceinDays: any = differenceinTime / (1000 * 3600 * 24);
        if ((differenceinDays >= 1) || (differenceinDays <= -1)) {
          const startDt = item.start.split(' ');
          const start = startDt[0] + ' ' + startDt[1] + ' ' + startDt[2] + ' 11:59 ' + 'PM';
          const endDt = item.end.split(' ');
          const end = endDt[0] + ' ' + endDt[1] + ' ' + endDt[2] + ' 12:00 ' + 'AM';
          list.push({ ...item, end: start, class: 'health' + (item.holiday
            ? ' holiday start'
            : '') });
          list.push({ ...item, start: end, class: 'health' + (item.holiday
            ? ' holiday end'
            : '') });
        }
        else {
          list.push({ ...item, class: 'health' + (item.holiday
            ? ' holiday start end'
            : '') });
        }
      }
      else {
        list.push(item);
      }
    });
    this.eventsShow = JSON.parse(JSON.stringify(list));
  }

  public setDefaultTaskToActive() {

  }

  public onEventClick(event: any) {
    setTimeout(() => {
      if (UIkit.offcanvas('#offcanvas-flip')) {
        UIkit.offcanvas('#offcanvas-flip').hide(); 
      }
      const filter: any = {
        taskIndex: event.taskIndex,
        taskListMonth: event.taskListMonth,
        type: event.type
      };
      const removekeys = ['taskListMonth', 'taskListIndex', 'taskIndex', 'type'];
      if (event.type !== 'taskType') {
        const eventToOpenArray = this.calendarEvents.filter(function (item: any) {
          for (const key in filter) {
            if (item[key] === undefined || item[key] != filter[key]) {
              return false;
            }
          }
          return true;
        });
        const newEventObj = JSON.parse(JSON.stringify({ ...eventToOpenArray[APP_CONST.ZERO] }));
        removekeys.forEach((keyToRemove: string) => {
          delete newEventObj[keyToRemove];
        });
        this.tempEventData = newEventObj;
        this.updateEventProp(newEventObj);
      }
    });
  }

  public onTaskClick(event: any) {
    setTimeout(() => {
      if (UIkit.offcanvas('#offcanvas-flip')) {
        UIkit.offcanvas('#offcanvas-flip').hide(); 
      }
      const filter: any = {
        taskIndex: event.taskIndex,
        taskListMonth: event.taskListMonth,
        type: event.type
      };
      const removekeys = ['taskListMonth', 'taskListIndex', 'taskIndex', 'type', 'startTime', 'endTime'];
      if (event.type === 'taskType') {
        const taskToOpenArray = this.calendarTasks.filter(function (item: any) {
          for (const key in filter) {
            if (item[key] === undefined || item[key] != filter[key]) {
              return false;
            }
          }
          return true;
        });
        const newTaskObj = JSON.parse(JSON.stringify({ ...taskToOpenArray[APP_CONST.ZERO] }));
        removekeys.forEach((keyToRemove: string) => {
          delete newTaskObj[keyToRemove];
        });
        this.tempTaskData = newTaskObj;
        this.updateProp(newTaskObj);
      }
    });

  }

  public onEventCreate() {
    setTimeout(() => {
      this.showCalender = true;
    }, 500);
  }

  public logTasks(type: string, event: any, calendarSequence: number) {
    this.tempTaskData = '';
    const elements: any = document.querySelectorAll('.customDivRowDates');
    const removeElements = (elms: any) => {
      elms.forEach((element: any) => element.remove()); 
    };
    if (type === 'view-change' && ((this.resetTKFilter && calendarSequence === APP_CONST.ZERO) || (this.resetEvntFilter && calendarSequence === APP_CONST.ONE))) {
      this.selectedDate = new Date(event.startDate);
      if (event.view === 'week') {
        this.tasksShow = JSON.parse(JSON.stringify(this.changableTaskList));
        removeElements(elements);
        setTimeout(() => {
          if (this.resetTKFilter || this.resetEvntFilter) {
            calendarSequence === APP_CONST.ZERO && (this.calendarViewTasks = 'week');
            calendarSequence === APP_CONST.ONE && (this.calendarViewEvents = 'week');
          }
        });
        const div = document.createElement('div');
        div.className = 'customDivRowDates';
        let stringDivToAdd = '';
        let dates = [];
        const startDate = new Date(event.startDate);
        const endDate = new Date(event.endDate);
        const year = startDate.getFullYear();
        const month = startDate.getMonth();
        let day = startDate.getDate();
        dates = [startDate];

        while (dates[dates.length - 1] < endDate) {
          dates.push(new Date(year, month, ++day));
        }
        dates.pop();
        this.startWeekDate = dates[APP_CONST.ZERO];
        const monthNames = ['January', 'February', 'March', 'April', 'May', 'June',
          'July', 'August', 'September', 'October', 'November', 'December'
        ];
        this.currentMonthName = monthNames[this.startWeekDate.getMonth()];
        for (let i = 0; i <= 6; i++) {
          const dateFind = new Date(dates[i]).getDate();
          const todayDate = new Date().getDate();
          const stringToAdd = todayDate === dateFind &&
            new Date(event.startDate).getMonth() === new Date().getMonth() &&
            new Date(event.startDate).getFullYear() === new Date().getFullYear()
            ? `<span class="today-date">${dateFind}<span>`
            : `${dateFind}`;
          stringDivToAdd += `<div class="item${i}">${stringToAdd}</div>`;
        }
        div.innerHTML = `<div class="grid-container">${stringDivToAdd}</div>`;
        const ele: any = document.querySelectorAll('.vuecal__flex .vuecal__body');
        if (ele[calendarSequence]) {
          ele[calendarSequence].parentNode && ele[calendarSequence].parentNode.insertBefore(div, ele[calendarSequence]);
        }
      }
      else {
        const monthNames = ['January', 'February', 'March', 'April', 'May', 'June',
          'July', 'August', 'September', 'October', 'November', 'December'
        ];
        this.currentMonthName = monthNames[event.startDate.getMonth()];
        this.startWeekDate = new Date(event.startDate);
        this.currentYearNumber = event.startDate.getFullYear();
        setTimeout(() => {
          if (this.resetTKFilter || this.resetEvntFilter) {
            calendarSequence === APP_CONST.ZERO && (this.calendarViewTasks = 'month');
            calendarSequence === APP_CONST.ONE && (this.calendarViewEvents = 'month');
          }
        });

        removeElements(elements);
      }
    }
    else if (type === 'cell-click') {
      if (calendarSequence == 0) {
        setTimeout(() => {
          this.openCreateTask(this.tempTaskData);
        }, 20);
      }
      if (calendarSequence == 1) {
        setTimeout(() => {
          this.openCreateTask(this.tempTaskData);
        }, 20);
      }
    }
  }

  public logEvents(type: string, event: any, calendarSequence: number) {
    this.tempEventData = '';
    if (event.startDate && (event.startDate.getMonth == undefined)) {
      event.startDate = new Date(event.startDate);
    }

    if (type == 'view-change' || type == 'ready') {
      const monthNames = ['January', 'February', 'March', 'April', 'May', 'June',
        'July', 'August', 'September', 'October', 'November', 'December'
      ];

      if (event.startDate.getMonth == undefined) {
        event.startDate = new Date(event.startDate);
      }
      this.startWeekDate = new Date(event.startDate);
      this.currentMonthName = monthNames[event.startDate.getMonth()];
      this.currentYearNumber = event.startDate.getFullYear();
      this.tempEventData = '';
    }
    const elements: any = document.querySelectorAll('.customDivRowDates');
    const removeElements = (elms: any) => {
      elms.forEach((element: any) => element.remove()); 
    };
    if ((type === 'view-change') && ((this.resetTKFilter && calendarSequence === APP_CONST.ZERO) || (this.resetEvntFilter && calendarSequence === APP_CONST.ONE))) {
      this.selectedDate = new Date(event.startDate);
      if (event.view === 'week') {
        this.eventsShow = JSON.parse(JSON.stringify(this.changableEventList));
        removeElements(elements);
        setTimeout(() => {
          if (this.resetTKFilter || this.resetEvntFilter) {
            calendarSequence === APP_CONST.ZERO && (this.calendarViewTasks = 'week');
            calendarSequence === APP_CONST.ONE && (this.calendarViewEvents = 'week');
          }
        });
        const div = document.createElement('div');
        div.className = 'customDivRowDates';
        let stringDivToAdd = '';
        const stringDivHoliday = '';
        let dates: any = [];
        const startDate = new Date(event.startDate);
        const endDate = new Date(event.endDate);
        const year = startDate.getFullYear();
        const month = startDate.getMonth();
        let day = startDate.getDate();
        dates = [startDate];

        while (dates[dates.length - 1] < endDate) {
          dates.push(new Date(year, month, ++day));
        }
        dates.pop();
        this.startWeekDate = dates[APP_CONST.ZERO];
        const monthNames = ['January', 'February', 'March', 'April', 'May', 'June',
          'July', 'August', 'September', 'October', 'November', 'December'
        ];
        this.currentMonthName = monthNames[this.startWeekDate.getMonth()];
        for (let i = 0; i <= 6; i++) {
          const dateFind = new Date(dates[i]).getDate();
          const todayDate = new Date().getDate();
          const stringToAdd = todayDate === dateFind &&
            new Date(event.startDate).getMonth() === new Date().getMonth() &&
            new Date(event.startDate).getFullYear() === new Date().getFullYear()
            ? `<span class="today-date">${dateFind}<span>`
            : `${dateFind}`;
          stringDivToAdd += `<div class="item${i}">${stringToAdd}</div>`;
        }
        const events: any = [];
        const originalEvent = JSON.parse(JSON.stringify(this.changableEventList));
        originalEvent.forEach((item: any) => {
          item.start = new Date(item.start);
          item.end = new Date(item.end);
          const check = dates.some((dt: any) => {
            if ((new Date(dt).setHours(0, 0, 0, 0) >= new Date(item.start).setHours(0, 0, 0, 0)) && (new Date(dt).setHours(0, 0, 0, 0) <= new Date(item.end).setHours(0, 0, 0, 0))) {
              return true;
            }
            return false;
          });
          if (check) {
            events.push(item);
          }
        });
        const holidayEvents = events.filter((item: any) => item.holiday === APP_CONST.TRUE);
        event.events = events.filter((item: any) => item.holiday === APP_CONST.FALSE);
        const holiday: any = [];
        dates.forEach((item: any) => {
          holiday.push({ date: item, holiday: false });
        });
        holiday.forEach((item: any) => {
          holidayEvents.forEach((holidayItem: any) => {
            const checkHoliday = item.date >= holidayItem.start && item.date <= holidayItem.end;
            if (!item.holiday && checkHoliday) {
              item.holiday = APP_CONST.TRUE;
            }
          });
        });


        let lastHoliday = APP_CONST.FALSE;
        let holidayDivs: string = '';
        let divElem = 0;
        const width = 14.2857;
        let fromDt;
        let toDt;
        for (const leave in holiday) {
          if (!lastHoliday && holiday[+leave].holiday) {
            divElem = 1;
            fromDt = holiday[+leave].date.getDate();
            lastHoliday = APP_CONST.TRUE;
          }
          else if (lastHoliday && holiday[+leave].holiday) {
            divElem += 1;
            toDt = holiday[+leave].date.getDate();
          }
          else if (lastHoliday && !holiday[+leave].holiday) {
            lastHoliday = APP_CONST.FALSE;
            if (divElem === APP_CONST.ONE) {
              holidayDivs += `<div class="holiday_mark"></div><div class="holiday_box" style="width:${(width * divElem) - 0.5}%">${'Holiday On ' + fromDt}</div>`;
            }
            else {
              holidayDivs += `<div class="holiday_mark"></div><div class="holiday_box" style="width:${(width * divElem) - 0.5}%">${'Holiday From ' + fromDt + '-' + toDt}</div>`;
            }
            holidayDivs += `<div class="empty" style="width:${width}%"></div>`;
          }
          else {
            holidayDivs += `<div class="empty" style="width:${width}%"></div>`;
          }

          if (lastHoliday && holiday[+leave].holiday && ((holiday.length - APP_CONST.ONE) === +leave)) {
            if (divElem === APP_CONST.ONE) {
              holidayDivs += `<div class="holiday_mark"></div><div class="holiday_box" style="width:${(width * divElem) - 0.5}%">${'Holiday On ' + fromDt}</div>`;
            }
            else {
              holidayDivs += `<div class="holiday_mark"></div><div class="holiday_box" style="width:${(width * divElem) - 0.5}%">${'Holiday From ' + fromDt + '-' + toDt}</div>`;
            }
          }
        }

        div.innerHTML = `<div class='holiday_week_view'>${holidayDivs}</div>` + `<div class="grid-container">${stringDivToAdd}</div>`;
        const ele: any = document.querySelectorAll('.vuecal__flex .vuecal__body');
        if (ele[calendarSequence]) {
          ele[calendarSequence].parentNode && ele[calendarSequence].parentNode.insertBefore(div, ele[calendarSequence]);
        }

        setTimeout(() => {
          document.querySelectorAll('.week-view .vuecal__event.holiday').forEach(function (a) {
            a && a.setAttribute('style', `background-color:${APP_CONST.STYLE.COLOR.NEUTRAL_200};height:100%`);
            let child = a.lastElementChild;
            while (child) {
              a.removeChild(child);
              child = a.lastElementChild;
            }

          });
        });

      }
      else {
        this.changeCalMonthView();
        setTimeout(() => {
          if (this.resetTKFilter || this.resetEvntFilter) {
            calendarSequence === APP_CONST.ZERO && (this.calendarViewTasks = 'month');
            calendarSequence === APP_CONST.ONE && (this.calendarViewEvents = 'month');
          }
        });

        removeElements(elements);
      }
    }
    else if (type === 'cell-click') {
      if (calendarSequence == 0) {
        setTimeout(() => {
          this.openCreateEvent(this.tempEventData);
        }, 20);
      }
      if (calendarSequence == 1) {
        setTimeout(() => {
          this.openCreateEvent(this.tempEventData);
        }, 20);
      }
    }
  }

  public arrowCalendar(event: any) {

  }

  checkDoneStatus(changedTask: any) {
    let doneTaskLength = 0;
    for (const obj in this.taskList) {
      this.taskList[obj].task.forEach((task: any, idx: number) => {
        if (task.id === changedTask.id) {
          task.status.state = changedTask.status.state;
        }
        if (task.status.state === 'Done') {
          doneTaskLength += 1;
        }
      });
    }
    return (doneTaskLength === 1);
  }

  confirmationPopup(e: any) {
    const id: any = this.getProgramId();
    if (e.state === 'updateTask') {

      if (e.ObjPlannerTask.isRemoved) {
        e.ObjPlannerTask.isRemoved = false;
        e.ObjPlannerTask.status.id = 1;
        e.ObjPlannerTask.status.state = 'Active';
        this.preparedSkippedTask(e.ObjPlannerTask);
      }
      else if ((!e.ObjPlannerTask.isRemoved) && (e.ObjPlannerTask.status.state === 'Active')) {
        for (const months in this.temptaskList) {
          for (let tk = 0; tk < this.temptaskList[months].task.length; tk++) {
            if (this.temptaskList[months].task[tk].id === e.ObjPlannerTask.id) {
              this.temptaskList[months].task[tk].status.id = 1;
              this.temptaskList[months].task[tk].status.state = 'Active';
              e.ObjPlannerTask.status.id = 1;
              this.temptaskList[months].active.push(e.ObjPlannerTask);
              this.itemToFilter(this.currentFilterArr);
              break;
            }
          }
        }
      }
      else if ((!e.ObjPlannerTask.isRemoved) && (e.ObjPlannerTask.status.state === 'Done')) {
        for (const months in this.temptaskList) {
          for (let tk = 0; tk < this.temptaskList[months].task.length; tk++) {
            if (this.temptaskList[months].task[tk].id === e.ObjPlannerTask.id) {
              this.temptaskList[months].task[tk].status.id = 2;
              this.temptaskList[months].task[tk].status.state = 'Done';
              e.ObjPlannerTask.status.id = 2;
              this.temptaskList[months].done.push(e.ObjPlannerTask);
              this.itemToFilter(this.currentFilterArr);
              break;
            }
          }
        }
      }

      this.taskTitle = e.ObjPlannerTask.title;
      if (UIkit.offcanvas('#offcanvas-flip-readonly')) {
        UIkit.offcanvas('#offcanvas-flip-readonly').hide(); 
      }
      if (e.ObjPlannerTask.status.state === 'Done' && e.isFirstUpdated) {
        this.taskTitle = this.userDetails.firstName;
        if (UIkit.modal('#state-change-confirmation')) {
          UIkit.modal('#state-change-confirmation').show(); 
        }
      }
      this.getTaskListByProgram(this.programData, id);

    }
    else if (e.state === 'modifyTask') {
      this.updateTaskList(e.ObjPlannerTask);
      if (UIkit.offcanvas('#taskupdate')) {
        UIkit.offcanvas('#taskupdate').hide(); 
      }
      this.getTaskListByProgram(this.programData, id);
    }
    else if (e.state === 'deleteTask') {
      this.deleteTask(e);
      this.getTaskListByProgram(this.programData, id);
      if (UIkit.offcanvas('#offcanvas-flip-readonly')) {
        UIkit.offcanvas('#offcanvas-flip-readonly').hide(); 
      }

    }
    else if (e.state === 'addEvent') {
      this.addEventInList(e);
      this.getEventListByProgram(this.programData, id);
    }
    else if (e.state === 'updateEvent') {
      this.updateEvent(e);
      if (UIkit.offcanvas('#offcanvas-flip-event')) {
        UIkit.offcanvas('#offcanvas-flip-event').hide(); 
      }
      this.getEventListByProgram(this.programData, id);
    }
    else if (e.state === 'deleteEvent') {
      this.deleteEvent(e);
      this.getEventListByProgram(this.programData, id);
      if (UIkit.offcanvas('#offcanvas-event-readonly')) {
        UIkit.offcanvas('#offcanvas-event-readonly').hide(); 
      }

    }
    else {
      this.addTaskInList(e);
      this.taskTitle = e.title;
      if (UIkit.modal('#modal-task-created')) {
        UIkit.modal('#modal-task-created').show(); 
      }
    }
  }

  updateEvent(singleEvent: any) {

    const eventMonth = this.prepareTaskEventKey(singleEvent.startDate);
    singleEvent.actualstartDate = singleEvent.startDate;
    singleEvent.actualendDate = singleEvent.endDate;
    if ((!this.eventDate.endDate) && (!this.eventDate.startDate)) {
      this.eventDate.startDate = singleEvent.startDate;
      this.eventDate.endDate = singleEvent.endDate;
    }
    else if ((this.eventDate.endDate) && (this.eventDate.startDate)) {
      if ((this.prepareTaskEventKey(this.eventDate.startDate) === this.prepareTaskEventKey(singleEvent.startDate))) {
        this.eventDate.startDate = singleEvent.startDate;
        this.eventDate.endDate = singleEvent.endDate;
      }
      else if ((this.prepareTaskEventKey(this.eventDate.startDate) !== this.prepareTaskEventKey(singleEvent.startDate))) {
        if ((this.convertDateInGMT(this.eventDate.startDate) > this.convertDateInGMT(singleEvent.startDate))) {
          this.eventDate.startDate = singleEvent.startDate;
        }
        if (this.convertDateInGMT(this.eventDate.endDate) < this.convertDateInGMT(singleEvent.endDate)) {
          this.eventDate.endDate = singleEvent.endDate;
        }
      }
    }
    const pStartMonth = this.deriveMonth(this.eventDate.startDate);
    const pStartYear = this.getYearFromDate(this.eventDate.startDate);
    const pEndMonth = this.deriveMonth(this.eventDate.endDate);
    const pEndYear = this.getYearFromDate(this.eventDate.endDate);
    const startDate = pStartMonth + ' ' + pStartYear;
    const endDate = pEndMonth + ' ' + pEndYear;
    const monthDiff = this.getMonthDiff(startDate, endDate);
    this.addMonthByTaskDate(eventMonth, singleEvent, 'event');
    const clonedEventList = JSON.parse(JSON.stringify(this.eventList));
    this.updateTaskDataStructure(monthDiff, 'event');
    monthDiff.length > 0 && monthDiff.forEach((item: any, index: number) => {
      if (!clonedEventList[item.month + ' ' + item.year]) {
        this.eventList[item.month + ' ' + item.year] = this.prepareMissingMonthData(item.month, item.year, 'event');
      }
      else {
        this.eventList[item.month + ' ' + item.year] = { ...clonedEventList[item.month + ' ' + item.year] };
      }
    });
    let eventIndex = -1;
    let oldMonth = '';
    let oldYear = '';
    for (const months in this.tempEventList) {
      for (let ev = 0; ev < this.tempEventList[months].task.length; ev++) {
        if (this.tempEventList[months].task[ev].id === singleEvent.id) {
          oldMonth = this.tempEventList[months].startMonth;
          oldYear = this.tempEventList[months].year;
          break;
        }
      }
    }

    const task: any = this.tempEventList[oldMonth + ' ' + oldYear];
    for (let ev = 0; ev < task.task.length; ev++) {
      if (task.task[ev].id === singleEvent.id) {
        oldMonth = task.startMonth;
        oldYear = task.year;
        eventIndex = ev;
        break;
      }
    }
    if (eventIndex >= 0) {
      singleEvent.startDate = new Date(singleEvent.actualstartDate);
      const tempEvent = JSON.parse(JSON.stringify(this.eventList));
      tempEvent[oldMonth + ' ' + oldYear].task.splice(eventIndex, 1);
      tempEvent[eventMonth].task.push(singleEvent);
      tempEvent[eventMonth].totalTask.push(singleEvent);
      tempEvent[oldMonth + ' ' + oldYear].totalTask.splice(singleEvent, 1);
      tempEvent[eventMonth].task = tempEvent[eventMonth].task.sort(function (a: any, b: any) {
        return new Date(a.actualstartDate).getTime() - new Date(b.actualstartDate).getTime(); 
      });
      singleEvent.startDate = APP_UTILITIES.formatDate(singleEvent.startDate);
      singleEvent.endDate = APP_UTILITIES.formatDate(singleEvent.endDate);
      singleEvent.displayDate = APP_UTILITIES.dayFormat(singleEvent.startDate);
      singleEvent.displayMonth = APP_UTILITIES.monthFormat(singleEvent.startDate);
      this.eventList = JSON.parse(JSON.stringify(tempEvent));
      this.itemToFilter(this.currentFilterArr);
    }
  }

  updateTaskList(singleTask: any) {
    singleTask.actualstartDate = singleTask.startDate;
    singleTask.actualendDate = singleTask.endDate;
    const taskMonth = this.prepareTaskEventKey(singleTask.startDate);
    if ((!this.taskDate.endDate) && (!this.taskDate.startDate)) {
      this.taskDate.startDate = singleTask.startDate;
      this.taskDate.endDate = singleTask.endDate;
    }
    else if ((this.taskDate.endDate) && (this.taskDate.startDate)) {
      if ((this.prepareTaskEventKey(this.taskDate.startDate) === this.prepareTaskEventKey(singleTask.startDate))) {
        this.taskDate.startDate = singleTask.startDate;
        this.taskDate.endDate = singleTask.endDate;
      }
      else if ((this.prepareTaskEventKey(this.taskDate.startDate) !== this.prepareTaskEventKey(singleTask.startDate))) {
        if ((this.convertDateInGMT(this.taskDate.startDate) > this.convertDateInGMT(singleTask.startDate))) {
          this.taskDate.startDate = singleTask.startDate;
        }
        if (this.convertDateInGMT(this.taskDate.endDate) < this.convertDateInGMT(singleTask.endDate)) {
          this.taskDate.endDate = singleTask.endDate;
        }
      }
    }
    const pStartMonth = this.deriveMonth(this.taskDate.startDate);
    const pStartYear = this.getYearFromDate(this.taskDate.startDate);
    const pEndMonth = this.deriveMonth(this.taskDate.endDate);
    const pEndYear = this.getYearFromDate(this.taskDate.endDate);
    const startDate = pStartMonth + ' ' + pStartYear;
    const endDate = pEndMonth + ' ' + pEndYear;
    const monthDiff = this.getMonthDiff(startDate, endDate);
    this.addMonthByTaskDate(taskMonth, singleTask, 'task');
    const clonedTaskList = JSON.parse(JSON.stringify(this.taskList));
    this.updateTaskDataStructure(monthDiff, 'task');
    monthDiff.length > 0 && monthDiff.forEach((item: any, index: number) => {
      if (!clonedTaskList[item.month + ' ' + item.year]) {
        this.taskList[item.month + ' ' + item.year] = this.prepareMissingMonthData(item.month, item.year, 'task');
      }
      else {
        this.taskList[item.month + ' ' + item.year] = { ...clonedTaskList[item.month + ' ' + item.year] };
      }
    });
    let taskIndex = -1;
    let oldMonth = '';
    let oldYear = '';
    for (const months in this.taskList) {
      for (let tk = 0; tk < this.taskList[months].task.length; tk++) {
        if (this.taskList[months].task[tk].id === singleTask.id) {
          oldMonth = this.taskList[months].startMonth;
          oldYear = this.taskList[months].year;
          break;
        }
      }
    }

    const task: any = this.taskList[oldMonth + ' ' + oldYear];
    for (let tk = 0; tk < task.task.length; tk++) {
      if (task.task[tk].id === singleTask.id) {
        oldMonth = task.startMonth;
        oldYear = task.year;
        taskIndex = tk;
        break;
      }
    }

    if (taskIndex >= 0) {
      singleTask.startDate = new Date(singleTask.actualstartDate);
      const tempTask = JSON.parse(JSON.stringify(this.taskList));
      tempTask[oldMonth + ' ' + oldYear].task.splice(taskIndex, 1);
      tempTask[taskMonth].task.push(singleTask);
      tempTask[taskMonth].totalTask.push(singleTask);
      tempTask[oldMonth + ' ' + oldYear].totalTask.splice(taskIndex, 1);
      tempTask[taskMonth].task = tempTask[taskMonth].task.sort(function (a: any, b: any) {
        return new Date(a.actualstartDate).getTime() - new Date(b.actualstartDate).getTime(); 
      });
      singleTask.startDate = APP_UTILITIES.formatDate(singleTask.startDate);
      singleTask.endDate = APP_UTILITIES.formatDate(singleTask.endDate);
      this.blankFilterTaskCount(oldMonth, oldYear, taskMonth, singleTask, tempTask, taskIndex);
      this.taskList = JSON.parse(JSON.stringify(tempTask));
      this.itemToFilter(this.currentFilterArr);
    }
  }

  blankFilterTaskCount(oldMonth: string, oldYear: any, taskMonth: any, singleTask: any, tempTask: any, taskIndex: number) {
    tempTask[taskMonth].currentDate = this.currentDate;
    if (this.currentFilterArr.length === 0) {
      tempTask[oldMonth + ' ' + oldYear].task.forEach((item: any, index: number) => {
        if (item.startDate !== this.currentDate) {
          tempTask[oldMonth + ' ' + oldYear].currentDateCount -= 1;
        }
      });

      tempTask[taskMonth].currentDateCount = 0;
      tempTask[taskMonth].task.forEach((item: any, index: number) => {
        if (item.startDate === this.currentDate) {
          tempTask[taskMonth].currentDateCount += 1;
        }
      });
    }
    else {
      tempTask[oldMonth + ' ' + oldYear].task.forEach((item: any, index: number) => {
        if (item.startDate !== this.currentDate) {
          tempTask[oldMonth + ' ' + oldYear].currentDateCount -= 1;
        }
      });

      tempTask[taskMonth].task.forEach((item: any, index: number) => {
        if (item.startDate === this.currentDate) {
          tempTask[taskMonth].currentDateCount += 1;
        }
      });
    }
  }


  addEventInList(singleEvnt: any) {
    const eventMonth = this.prepareTaskEventKey(singleEvnt.startDate);
    singleEvnt.actualstartDate = singleEvnt.startDate;
    singleEvnt.actualendDate = singleEvnt.endDate;
    if ((!this.eventDate.endDate) && (!this.eventDate.startDate)) {
      this.eventDate.startDate = singleEvnt.startDate;
      this.eventDate.endDate = singleEvnt.endDate;
    }
    else if ((this.eventDate.endDate) && (this.eventDate.startDate)) {
      if ((this.prepareTaskEventKey(this.eventDate.startDate) === this.prepareTaskEventKey(singleEvnt.startDate))) {
        this.eventDate.startDate = singleEvnt.startDate;
        this.eventDate.endDate = singleEvnt.endDate;
      }
      else if ((this.prepareTaskEventKey(this.eventDate.startDate) !== this.prepareTaskEventKey(singleEvnt.startDate))) {
        if ((this.convertDateInGMT(this.eventDate.startDate) > this.convertDateInGMT(singleEvnt.startDate))) {
          this.eventDate.startDate = singleEvnt.startDate;
        }
        if (this.convertDateInGMT(this.eventDate.endDate) < this.convertDateInGMT(singleEvnt.endDate)) {
          this.eventDate.endDate = singleEvnt.endDate;
        }
      }
    }
    const pStartMonth = this.deriveMonth(this.eventDate.startDate);
    const pStartYear = this.getYearFromDate(this.eventDate.startDate);
    const pEndMonth = this.deriveMonth(this.eventDate.endDate);
    const pEndYear = this.getYearFromDate(this.eventDate.endDate);
    const startDate = pStartMonth + ' ' + pStartYear;
    const endDate = pEndMonth + ' ' + pEndYear;
    const monthDiff = this.getMonthDiff(startDate, endDate);
    this.addMonthByTaskDate(eventMonth, singleEvnt, 'event');
    const clonedEventList = JSON.parse(JSON.stringify(this.eventList));
    this.updateTaskDataStructure(monthDiff, 'event');
    monthDiff.length > 0 && monthDiff.forEach((item: any, index: number) => {
      if (!clonedEventList[item.month + ' ' + item.year]) {
        this.eventList[item.month + ' ' + item.year] = this.prepareMissingMonthData(item.month, item.year, 'event');
      }
      else {
        this.eventList[item.month + ' ' + item.year] = { ...clonedEventList[item.month + ' ' + item.year] };
      }
    });

    this.eventCurrentAndTotalCount(singleEvnt, eventMonth);
    for (const monthName in this.eventList) {
      if (eventMonth === monthName) {
        this.eventList[eventMonth].task.push({ ...singleEvnt });
      }
    }
    const tempEvent: any = [];
    this.eventList[eventMonth].task.forEach((item: any, index: number) => {
      const evnetToAdd = {
        'id': item.id,
        'title': item.title || item.name,
        'location': item.location || '',
        'startDate': APP_UTILITIES.formatDate(item.startDate),
        'endDate': APP_UTILITIES.formatDate(item.endDate) || '',
        'actualstartDate': item.actualstartDate || '',
        'actualendDate': item.actualstartDate || '',
        'displayDate': APP_UTILITIES.dayFormat(item.startDate),
        'displayMonth': APP_UTILITIES.monthFormat(item.startDate),
        'description': item.description,
        'status': {
          'id': item.name
            ? 1
            : item.status.id,
          'state': item.name
            ? 'Active'
            : item.status.state
        },
        'createdBy': item.createdBy || '',
        'createdAt': item.createdAt || '',
        'startMonth': this.deriveMonth(item.startDate),
        'year': this.getYearFromDate(item.startDate),
        'startTime': item.holiday
          ? ''
          : item.startTime
            ? item.startTime
            : item.actualstartDate.split(' ').length > 1
              ? item.actualstartDate.split(' ')[APP_CONST.ONE]
              : '00:00',
        'endTime': item.holiday
          ? ''
          : item.endTime
            ? item.endTime
            : item.actualendDate.split(' ').length > 1
              ? item.actualendDate.split(' ')[APP_CONST.ONE]
              : '01:00',
        'programId': this.getProgramId(),
        'siteId': (this.currentRoleId === 6 || this.currentRoleId === 1 || this.currentRoleId === 2)
          ? this.currentSiteId
          : 0,
        'holiday': item.holiday
          ? APP_CONST.TRUE
          : APP_CONST.FALSE,
        'startDay': APP_UTILITIES.dayFormat(item.startDate),
        'endDay': APP_UTILITIES.dayFormat(item.endDate),
        'fullMonth': APP_UTILITIES.fullMonthFormat(item.startDate),
        'fullEndMonth': APP_UTILITIES.fullMonthFormat(item.endDate)
      };
      tempEvent.push(evnetToAdd);
    });
    const sortedActivities = tempEvent.sort(function (a: any, b: any) {
      const dateA: any = new Date(a.actualstartDate);
      const dateB: any = new Date(b.actualstartDate);
      return dateA - dateB;
    });
    const finalArr: any = [];
    sortedActivities.forEach((item: any, index: number) => {
      const startTime = item.holiday
        ? ''
        : item.startTime
          ? item.startTime
          : item.actualstartDate.split(' ').length > 1
            ? item.actualstartDate.split(' ')[APP_CONST.ONE]
            : '00:00';
      const endTime = item.holiday
        ? ''
        : item.endTime
          ? item.endTime
          : item.actualendDate.split(' ').length > 1
            ? item.actualendDate.split(' ')[APP_CONST.ONE]
            : '01:00';
      const evnetToAdd = {
        'id': item.id,
        'title': item.title || item.name,
        'location': item.location || '',
        'startDate': APP_UTILITIES.formatDate(item.startDate),
        'endDate': APP_UTILITIES.formatDate(item.endDate),
        'actualstartDate': item.actualstartDate || '',
        'actualendDate': item.actualendDate || '',
        'displayDate': APP_UTILITIES.dayFormat(item.startDate),
        'displayMonth': APP_UTILITIES.monthFormat(item.startDate),
        'description': item.description,
        'status': {
          'id': item.name
            ? 1
            : item.status.id,
          'state': item.name
            ? 'Active'
            : item.status.state
        },
        'createdBy': item.createdBy || '',
        'createdAt': item.createdAt || '',
        'startMonth': this.deriveMonth(item.startDate),
        'year': this.getYearFromDate(item.startDate),
        'startTime': item.holiday
          ? ''
          : startTime,
        'endTime': item.holiday
          ? ''
          : endTime,
        'programId': this.getProgramId(),
        'siteId': (this.currentRoleId === 6 || this.currentRoleId === 1 || this.currentRoleId === 2)
          ? this.currentSiteId
          : 0,
        'holiday': item.holiday
          ? APP_CONST.TRUE
          : APP_CONST.FALSE,
        'startDay': APP_UTILITIES.dayFormat(item.startDate),
        'endDay': APP_UTILITIES.dayFormat(item.endDate),
        'fullMonth': APP_UTILITIES.fullMonthFormat(item.startDate),
        'fullEndMonth': APP_UTILITIES.fullMonthFormat(item.endDate)
      };
      finalArr.push(evnetToAdd);

    });
    this.eventList[eventMonth].task = finalArr;
    this.eventList[eventMonth].totalTask = finalArr;
    this.eventList[eventMonth].currentDate = this.currentDate;
    this.itemToFilter(this.currentFilterArr);

  }

  public eventCurrentAndTotalCount(item: any, eventMonth: string) {
    if ((this.currentDate === APP_UTILITIES.formatDate(item.startDate))) {
      this.eventList[eventMonth].currentDateCount += 1;
    }
    else if ((this.convertDateInGMT(this.currentDate) < this.convertDateInGMT(item.startDate)) && (item.status.state === 'Active')) {
      this.eventList[eventMonth].totalCount += 1;
    }
  }

  public addMonthByTaskDate(taskMonth: string, singleTask: any, type: any) {
    const splitMonthYear = taskMonth.split(' ');
    if (type === 'task') {
      if (!this.taskList.hasOwnProperty(taskMonth)) {
        this.taskList[taskMonth] = {
          'task': [],
          'startDate': [],
          'month': '',
          'currentDate': '',
          'currentDateCount': 0,
          'totalCount': 0,
          'monthIndex': this.getMonthIndex[splitMonthYear[0]].monthIndex + 1,
          'startMonth': splitMonthYear[0],
          'year': new Date(singleTask.actualstartDate).getFullYear(),
          'active': [],
          'done': [],
          'type': 'task',
          'totalTask': []
        };
      }
    }
    else {
      if (!this.eventList.hasOwnProperty(taskMonth)) {
        this.eventList[taskMonth] = {
          'task': [],
          'startDate': [],
          'month': '',
          'currentDate': '',
          'currentDateCount': 0,
          'totalCount': 0,
          'monthIndex': this.getMonthIndex[splitMonthYear[0]].monthIndex + 1,
          'startMonth': splitMonthYear[0],
          'year': new Date(singleTask.actualstartDate).getFullYear(),
          'active': [],
          'done': [],
          'type': 'task',
          'totalTask': []
        };
      }
    }
  }

  public updateTaskDataStructure(monthDiff: any, type: any) {
    if (type === 'task') {
      this.taskList = {};
      monthDiff.length > 0 && monthDiff.forEach((item: any, index: number) => {
        if (item.month && item.year) {
          this.taskList[item.month + ' ' + item.year] = {
            'task': [],
            'startDate': [],
            'month': '',
            'currentDate': '',
            'currentDateCount': 0,
            'totalCount': 0,
            'monthIndex': this.getMonthIndex[item.month].monthIndex + 1,
            'startMonth': item.month,
            'year': item.year,
            'active': [],
            'done': [],
            'type': 'task',
            'totalTask': []
          };
        }
      });
    }
    else if (type === 'event') {
      this.eventList = {};
      monthDiff.length > 0 && monthDiff.forEach((item: any, index: number) => {
        this.eventList[item.month + ' ' + item.year] = {
          'task': [],
          'startDate': [],
          'month': '',
          'currentDate': '',
          'currentDateCount': 0,
          'totalCount': 0,
          'monthIndex': this.getMonthIndex[item.month].monthIndex + 1,
          'startMonth': item.month,
          'year': item.year,
          'active': [],
          'done': [],
          'type': 'task',
          'totalTask': []
        };
      });
    }
  }

  public prepareMissingMonthData(month: any, year: any, type: any) {
    if (type === 'task') {
      return {
        'task': [],
        'startDate': [],
        'month': '',
        'currentDate': '',
        'currentDateCount': 0,
        'totalCount': 0,
        'monthIndex': this.getMonthIndex[month].monthIndex + 1,
        'startMonth': month,
        'year': year,
        'active': [],
        'done': [],
        'type': 'task',
        'totalTask': []
      };
    }
    else if (type === 'event') {
      return {
        'task': [],
        'startDate': [],
        'month': '',
        'currentDate': '',
        'currentDateCount': 0,
        'totalCount': 0,
        'monthIndex': this.getMonthIndex[month].monthIndex + 1,
        'startMonth': month,
        'year': year,
        'active': [],
        'done': [],
        'type': 'event',
        'totalTask': []
      };
    }
  }

  public addTaskInList(singleTask: any) {
    const taskMonth = this.prepareTaskEventKey(singleTask.startDate);
    singleTask.actualstartDate = singleTask.startDate;
    singleTask.actualendDate = singleTask.endDate;
    if ((!this.taskDate.endDate) && (!this.taskDate.startDate)) {
      this.taskDate.startDate = singleTask.startDate;
      this.taskDate.endDate = singleTask.endDate;
    }
    else if ((this.taskDate.endDate) && (this.taskDate.startDate)) {
      if ((this.prepareTaskEventKey(this.taskDate.startDate) === this.prepareTaskEventKey(singleTask.startDate))) {
        this.taskDate.startDate = singleTask.startDate;
        this.taskDate.endDate = singleTask.endDate;
      }
      else if ((this.prepareTaskEventKey(this.taskDate.startDate) !== this.prepareTaskEventKey(singleTask.startDate))) {
        if ((this.convertDateInGMT(this.taskDate.startDate) > this.convertDateInGMT(singleTask.startDate))) {
          this.taskDate.startDate = singleTask.startDate;
        }
        if (this.convertDateInGMT(this.taskDate.endDate) < this.convertDateInGMT(singleTask.endDate)) {
          this.taskDate.endDate = singleTask.endDate;
        }
      }
    }
    const pStartMonth = this.deriveMonth(this.taskDate.startDate);
    const pStartYear = this.getYearFromDate(this.taskDate.startDate);
    const pEndMonth = this.deriveMonth(this.taskDate.endDate);
    const pEndYear = this.getYearFromDate(this.taskDate.endDate);
    const startDate = pStartMonth + ' ' + pStartYear;
    const endDate = pEndMonth + ' ' + pEndYear;
    const monthDiff = this.getMonthDiff(startDate, endDate);
    this.addMonthByTaskDate(taskMonth, singleTask, 'task');
    const clonedTaskList = JSON.parse(JSON.stringify(this.taskList));
    this.updateTaskDataStructure(monthDiff, 'task');
    monthDiff.length > 0 && monthDiff.forEach((item: any, index: number) => {
      if (item.month && item.year) {
        if (!clonedTaskList[item.month + ' ' + item.year]) {
          this.taskList[item.month + ' ' + item.year] = this.prepareMissingMonthData(item.month, item.year, 'task');
        }
        else {
          this.taskList[item.month + ' ' + item.year] = { ...clonedTaskList[item.month + ' ' + item.year] };
        }
      }
    });
    this.taskCurrentAndTotalCount(singleTask, taskMonth);
    const taskList = JSON.parse(JSON.stringify(this.taskList));
    for (const monthName in this.taskList) {
      if (taskMonth === monthName) {
        taskList[taskMonth].task.push({ ...singleTask });
      }
    }
    const tempTask: any = [];
    taskList[taskMonth].task.forEach((item: any, index: number) => {
      const taskToAdd = {
        'id': item.id,
        'productId': item.productId,
        'productProgramId': item.productProgramId,
        'answerId': item.answerId,
        'cmsTaskId': item.cmsTaskId,
        'title': item.title,
        'startDate': new Date(item.startDate),
        'endDate': item.endDate,
        'description': item.description,
        'isRemoved': item.isRemoved,
        'assignedTo': item.assignedTo,
        'status': {
          'id': item.status.id,
          'state': item.status.state
        },
        'createdBy': item.createdBy,
        'createdAt': item.createdAt,
        'startMonth': this.deriveMonth(item.startDate),
        'year': this.getYearFromDate(item.startDate),
        'programId': this.getProgramId(),
        'siteId': (this.currentRoleId === 6 || this.currentRoleId === 1 || this.currentRoleId === 2)
          ? this.currentSiteId
          : 0
      };
      tempTask.push(taskToAdd);
    });
    const sortedActivities = tempTask.sort((a: any, b: any) => a.startDate - b.startDate);
    const finalArr: any = [];
    sortedActivities.forEach((item: any, index: number) => {
      const taskToAdd = {
        'id': item.id,
        'productId': item.productId,
        'productProgramId': item.productProgramId,
        'answerId': item.answerId,
        'cmsTaskId': item.cmsTaskId,
        'title': item.title,
        'startDate': APP_UTILITIES.formatDate(item.startDate),
        'endDate': APP_UTILITIES.formatDate(item.endDate),
        'description': item.description,
        'isRemoved': item.isRemoved,
        'assignedTo': item.assignedTo,
        'status': {
          'id': item.status.id,
          'state': item.status.state
        },
        'createdBy': item.createdBy,
        'createdAt': item.createdAt,
        'startMonth': this.deriveMonth(item.startDate),
        'year': this.getYearFromDate(item.startDate),
        'programId': this.getProgramId(),
        'siteId': (this.currentRoleId === 6 || this.currentRoleId === 1 || this.currentRoleId === 2)
          ? this.currentSiteId
          : 0
      };
      finalArr.push(taskToAdd);
    });
    taskList[taskMonth].task = finalArr;
    taskList[taskMonth].totalTask = finalArr;
    taskList[taskMonth].currentDate = this.currentDate;
    this.taskList = JSON.parse(JSON.stringify(taskList));
    this.itemToFilter(this.currentFilterArr);
  }

  public taskCurrentAndTotalCount(item: any, taskMonth: string) {
    if ((this.currentDate === APP_UTILITIES.formatDate(item.startDate))) {
      this.taskList[taskMonth].currentDateCount += 1;
    }
    else if ((this.convertDateInGMT(this.currentDate) < this.convertDateInGMT(item.startDate)) && (item.status.state === 'Active')) {
      this.taskList[taskMonth].totalCount += 1;
    }
  }

  public deleteTask(tasktObj: any) {
    const taskMonth = this.prepareTaskEventKey(tasktObj.startDate);
    const taskList = JSON.parse(JSON.stringify(this.taskList));
    const temptaskList = JSON.parse(JSON.stringify(this.temptaskList));
    for (const monthName in this.temptaskList) {
      if (taskMonth === monthName) {
        const eventIndex = this.temptaskList[taskMonth].task.findIndex((el: any) => (el.id === tasktObj.id));
        if (eventIndex > -1) {
          if (this.getCurrentMonthAndYear() === taskMonth) {
            if ((this.currentDate === APP_UTILITIES.formatDate(tasktObj.startDate))) {
              temptaskList[taskMonth].task.splice(eventIndex, 1);
              taskList[taskMonth].totalTask.splice(eventIndex, 1);
              taskList[taskMonth].task.splice(eventIndex, 1);
              temptaskList[taskMonth].currentDateCount -= 1;
            }
            if (((this.convertDateInGMT(this.currentDate) < this.convertDateInGMT(tasktObj.startDate)) || (this.convertDateInGMT(this.currentDate) > this.convertDateInGMT(tasktObj.startDate)))) {
              temptaskList[taskMonth].task.splice(eventIndex, 1);
              taskList[taskMonth].totalTask.splice(eventIndex, 1);
              taskList[taskMonth].task.splice(eventIndex, 1);
              temptaskList[taskMonth].totalCount -= 1;
            }
          }
          else {
            temptaskList[taskMonth].task.splice(eventIndex, 1);
            taskList[taskMonth].totalTask.splice(eventIndex, 1);
            taskList[taskMonth].task.splice(eventIndex, 1);
          }
        }
        this.temptaskList = JSON.parse(JSON.stringify(temptaskList));
        this.filterTaskWhenDeleted(taskList, taskMonth);
        this.taskList = JSON.parse(JSON.stringify(taskList));
        this.itemToFilter(this.currentFilterArr);
      }

    }
  }

  filterTaskWhenDeleted(taskList: any, taskMonth: any) {
    if (this.currentFilterArr.length === 0) {
      taskList[taskMonth].task.forEach((item: any, index: number) => {
        if (item.startDate === this.currentDate) {
          taskList[taskMonth].currentDateCount -= 1;
        }
      });
    }
  }

  public deleteEvent(evntObj: any) {
    const eventMonth = this.prepareTaskEventKey(evntObj.startDate);
    const eventList = JSON.parse(JSON.stringify(this.eventList));
    const tempEventList = JSON.parse(JSON.stringify(this.tempEventList));
    for (const monthName in this.tempEventList) {
      if (eventMonth === monthName) {
        const eventIndex = this.tempEventList[eventMonth].task.findIndex((el: any) => (el.id === evntObj.id));
        if (eventIndex > -1) {
          if (this.getCurrentMonthAndYear() === eventMonth) {
            if ((this.currentDate === APP_UTILITIES.formatDate(evntObj.startDate))) {
              tempEventList[eventMonth].task.splice(eventIndex, 1);
              eventList[eventMonth].totalTask.splice(eventIndex, 1);
              eventList[eventMonth].task.splice(eventIndex, 1);
              eventList[eventMonth].currentDateCount -= 1;
              tempEventList[eventMonth].currentDateCount -= 1;
            }
            if (((this.convertDateInGMT(this.currentDate) < this.convertDateInGMT(evntObj.startDate)) || (this.convertDateInGMT(this.currentDate) > this.convertDateInGMT(evntObj.startDate)))) {

              tempEventList[eventMonth].task.splice(eventIndex, 1);
              eventList[eventMonth].totalTask.splice(eventIndex, 1);
              eventList[eventMonth].task.splice(eventIndex, 1);
              tempEventList[eventMonth].totalCount -= 1;
            }
          }
          else {
            tempEventList[eventMonth].task.splice(eventIndex, 1);
            eventList[eventMonth].totalTask.splice(eventIndex, 1);
            eventList[eventMonth].task.splice(eventIndex, 1);
          }
        }
        this.tempEventList = JSON.parse(JSON.stringify(tempEventList));
        this.eventList = JSON.parse(JSON.stringify(eventList));
        this.itemToFilter(this.currentFilterArr);
      }
    }
  }

  closeConfirmation(e: any) {
    UIkit.modal('#modal-task-created').hide();
  }

  confirmState(e: any) {
    UIkit.modal('#state-change-confirmation').hide();
  }

  openCreateEvent(e: any) {
    if (!e && UIkit.offcanvas('#offcanvas-flip')) {
      UIkit.offcanvas('#offcanvas-flip').toggle();
    }

  }

  openCreateTask(e: any) {
    if (!e && UIkit.offcanvas('#offcanvas-flip')) {
      UIkit.offcanvas('#offcanvas-flip').toggle();
    }
  }



  updateProp(task: IProgramPlanner, bShow?: boolean) {
    setTimeout(() => {
      if (UIkit.offcanvas('#offcanvas-flip-readonly') && !bShow) {
        UIkit.offcanvas('#offcanvas-flip-readonly').show(); 
      }
      this.menu = false;
      this.tasktoUpdate = task;
      this.tasktoUpdate.programId = this.programData.programDto.id;
      this.tasktoUpdate.programStartDate = this.programData.programDto.startDate;
      this.tasktoUpdate.programEndDate = this.programData.programDto.endDate;
    });
  }

  openUpdateForm(taskDt: any) {
    this.updateProp(taskDt, true);
    if (UIkit.offcanvas('#taskupdate')) {
      UIkit.offcanvas('#taskupdate').show(); 
    }

  }

  showFilter() {
    this.openFilter = false;
  }

  checkFilter(e: any) {
    this.openFilter = e;
  }

  closeTaskForm(e: any) {
    if (UIkit.offcanvas('#taskupdate')) {
      UIkit.offcanvas('#taskupdate').toggle(); 
    }
  }

  public resetEventFilter() {
    this.resetFilter = true;
    this.resetTKFilter = false;
    this.resetEvntFilter = true;
    this.showCalender = false;
    this.scrollEventInView();
  }

  scrollEventInView() {
    setTimeout(() => {
      const el: any = document.getElementById('eventCurrDate');
      if (el) {
        const rect = el.getBoundingClientRect();
        const position = {
          top: rect.top + window.pageYOffset,
          left: rect.left + window.pageXOffset
        };
        window.scrollTo({
          top: (position.top - 200),
          behavior: 'smooth',
        });
      }
    }, 500);
  }

  public scrollEventList(event: any) {
    if (event) {
      this.scrollEventInView();
    }
  }

  printCalendar() {
    window.scrollTo(0, 0);
    const el = document.getElementById('printing') as HTMLElement;


    html2canvas(el, {
      width: 880,
      height: (this.resetTKFilter && this.calendarViewTasks == 'month') || (this.resetEvntFilter && this.calendarViewEvents == 'month')
        ? 800
        : (this.resetTKFilter && this.calendarViewTasks == 'week')
          ? 1400
          : 2300,
      backgroundColor: APP_CONST.STYLE.COLOR.NEUTRAL_100,
      scale: 3.4
    }).then((canvas: any) => {
      const finaldoc = new jsPDF('p', 'mm', 'a4');
      const width = finaldoc.internal.pageSize.getWidth();
      const height = finaldoc.internal.pageSize.getHeight() + 200;
      const finalimgData = canvas.toDataURL('image/jpeg', { multiplier: 8.428 });
      finaldoc.addImage(finalimgData, 'jpeg', 18, 5, width, height);
      printJS({ printable: finalimgData, type: 'image', showModal: true, style: '#printContent{ margin-left: 100px !important; } .modal-body {  margin-left: 100px;}', maxWidth: 100, header: null, css: ['./printDoc.css'] });
    }).catch((error: any) => {
    });
  }

  downloadCalendar() {
    window.scrollTo(0, 0);

    setTimeout(() => {
      const el = document.getElementById('printing') as HTMLElement;
      const divHeight: any = el.offsetHeight + ((this.resetTKFilter && this.calendarViewTasks == 'month') || (this.resetEvntFilter && this.calendarViewEvents == 'month')
        ? 360
        : (this.resetTKFilter && this.calendarViewTasks == 'week')
          ? 400
          : 2200);
      const divWidth: any = el.offsetWidth + 90;
      const ratio = divHeight / divWidth;
      html2canvas(el, {
        height: divHeight,
        width: divWidth,
      }).then((canvas: any) => {
        const image = canvas.toDataURL('image/jpeg', 1);
        const doc = new jsPDF('p', 'mm', [(canvas.width), (canvas.height)]);
        const width = doc.internal.pageSize.getWidth();
        const height = ratio * width;
        doc.addImage(image, 'JPEG', 0, 1, width - 14, height);
        doc.save('programPlan.pdf');
      }).catch((error: any) => {
      });
    }, 1000);
  }

  resetTaskFilter() {
    this.resetFilter = true;
    this.resetTKFilter = true;
    this.resetEvntFilter = false;
    this.showCalender = false;
    if (this.viewCheckVisible === 'calendar') {
      this.startWeekDate = new Date();
      const monthNames = ['January', 'February', 'March', 'April', 'May', 'June',
        'July', 'August', 'September', 'October', 'November', 'December'
      ];
      this.currentMonthName = monthNames[this.startWeekDate.getMonth()];
    }
  }

  openCreateTaskForm(e: any) {
    if (UIkit.offcanvas('#offcanvas-flip-program')) {
      UIkit.offcanvas('#offcanvas-flip-program').toggle(); 
    }

  }
  openCreateEventForm(e: any) {
    if (UIkit.offcanvas('#offcanvas-flip-event')) {
      UIkit.offcanvas('#offcanvas-flip-event').toggle(); 
    }

  }

  closeEventForm(e: any = {}) {
    UIkit.offcanvas('#offcanvas-flip-event').hide();
    this.eventToUpdate = { ...APP_CONST.PROGRAM_EVENT } as any;
  }

  goBack(e: any) {
    if (UIkit.offcanvas('#offcanvas-flip')) {
      UIkit.offcanvas('#offcanvas-flip').toggle(); 
    }
  }

  updateEventProp(task: IProgramPlanner, eShow?: boolean) {
    setTimeout(() => {
      if (UIkit.offcanvas('#offcanvas-event-readonly') && !eShow) {
        UIkit.offcanvas('#offcanvas-event-readonly').show(); 
      }
      this.EvtMenu = false;
      this.eventToUpdate = task;
      const id = this.programData.programDto && this.programData.programDto.id
        ? this.programData.programDto.id
        : 0;
      this.eventToUpdate.programId = id;
    });
  }

  editEventForm(eventDt: any) {
    this.updateEventProp(eventDt, true);
    if (UIkit.offcanvas('#offcanvas-flip-event')) {
      UIkit.offcanvas('#offcanvas-flip-event').show(); 
    }
  }

  editHolidayForm(eventDt: any) {
    this.updateEventProp(eventDt, true);
    if (UIkit.offcanvas('#offcanvas-flip-event')) {
      UIkit.offcanvas('#offcanvas-flip-event').show(); 
    }
  }

  openTaskList() {
    if (UIkit.offcanvas('#offcanvas-flip-readonly')) {
      UIkit.offcanvas('#offcanvas-flip-readonly').hide(); 
    }

  }

  showReadonlyTask() {
    if (UIkit.offcanvas('#offcanvas-flip-readonly')) {
      UIkit.offcanvas('#offcanvas-flip-readonly').show(); 
    }
  }

  openEventList() {
    if (UIkit.offcanvas('#offcanvas-event-readonly')) {
      UIkit.offcanvas('#offcanvas-event-readonly').hide(); 
    }
    this.eventToUpdate = { ...APP_CONST.PROGRAM_EVENT } as any;
  }


  goToReadOnly() {
    if (UIkit.offcanvas('#offcanvas-event-readonly')) {
      UIkit.offcanvas('#offcanvas-event-readonly').show(); 
    }

  }

  clearEventObj() {
    this.eventToUpdate = { ...APP_CONST.PROGRAM_EVENT } as any;
  }

  public markTaskDone(currTask: any, month: string, index: number) {
    if (currTask.status.state === 'Done') {
      return false;
    }
    else if (currTask.status.state === 'Active') {
      currTask.status.id = 4;
      currTask.status.state = 'Done';
      dashboardStore.condenseTaskUpdated(currTask).then((res: any) => {
        if (res && res.status === 200) {
          if (res.data.isFirstUpdated) {
            this.taskTitle = this.userDetails.firstName;
            if (UIkit.modal('#state-change-confirmation')) {
              UIkit.modal('#state-change-confirmation').show(); 
            }
          }
          this.prepareDoneTask(currTask, month, index);
        }
      });
    }
  }

  prepareDoneTask(currTask: any, month: string, index: number) {
    this.temptaskList[month].task[index].status.id = 2;
    this.temptaskList[month].task[index].status.state = 'Done';
    currTask.status.id = 2;
    this.temptaskList[month].done.push(currTask);
    this.itemToFilter(this.currentFilterArr);
  }

  public markTaskActive(currTask: any, month: string, index: number) {
    if (currTask.status.state === 'Done') {
      currTask.status.id = 1;
      currTask.status.state = 'Active';
      dashboardStore.condenseTaskUpdated(currTask).then((res: any) => {
        if (res && res.status === 200) {
          this.temptaskList[month].task[index].status.id = 1;
          this.temptaskList[month].task[index].status.state = 'Active';
          currTask.status.id = 1;
          this.temptaskList[month].active.push(currTask);
          this.itemToFilter(this.currentFilterArr);

        }
      });
    }
  }

  public markSkippedTaskActive(currTask: any, index: number) {
    currTask.status.id = 1;
    currTask.status.state = 'Active';
    currTask.isRemoved = false;
    dashboardStore.condenseTaskUpdated(currTask).then((res: any) => {
      if (res && res.status === 200) {
        this.preparedSkippedTask(currTask);
      }
    });
  }

  public preparedSkippedTask(currTask: any) {
    this.taskList[currTask.startMonth].task.push(currTask);
    this.taskList[currTask.startMonth].active.push(currTask);
    this.taskList[currTask.startMonth].totalTask.push(currTask);
    this.temptaskList[currTask.startMonth].task.push(currTask);
    this.temptaskList[currTask.startMonth].active.push(currTask);
    this.itemToFilter(this.currentFilterArr);
  }

  public goToDashboard() {
    this.$router.push('/home');
  }
  callHover(title: string) {
    this.listIndex = title;
    this.hovering = true;
  }
  callHoverOut() {
    this.listIndex = null;
    this.hovering = false;
  }

}