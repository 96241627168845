import { Vue, Component, Prop } from 'vue-property-decorator';
import { ScreenText } from '@/lang/ScreenText';
import APP_CONST from '@/constants/AppConst';

@Component
export class StateChangeConfirmation extends Vue {
    private objScreenText: ScreenText = new ScreenText();

    @Prop()
    title! : string;

  
    public getScreenText(key: string): string {
      return this.objScreenText.getScreenText(key);
    }

    closePopup() {
      this.$emit(APP_CONST.CLOSE);
    }

   

}