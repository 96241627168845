import { Vue, Component, Watch, Prop } from 'vue-property-decorator';
import { ScreenText } from '@/lang/ScreenText';
import programPlanner from '@/store/modules/programPlannerStore';
import commonEntry from '@/store/modules/commonEntry';
import { IProgramPlanner } from '@/Model/programModel';
import APP_UTILITIES from '@/utilities/commonFunctions';
import APP_CONST from '@/constants/AppConst';
import DatepickerComponent from '@/ui-components/datepicker/DatepickerComponent.vue';

@Component({
  components: {
    'datepicker': DatepickerComponent
  }
})
export default class ProgramTaskComponent extends Vue {
    private objScreenText: ScreenText = new ScreenText();
    plannerTaskObj: IProgramPlanner = { ...APP_CONST.PROGRAM_PLANNER } as IProgramPlanner;
    validatedFields = APP_CONST.VALIDATE_PROG_PLAN_FORM;
    public currentSiteId : any= 0;
    public currentRoleId : any = 0;
    public userRoles : any = [];
    public programId : any = 0;
    public allFieldRequired :boolean =false;
    public dateResponseError : boolean =false;
    public dateResponse :string ='';
    public dateType :string ='';
    public programSubmitCount : number = 0;
    public isTaskNameError : boolean = false;

    @Prop()
    userDetails!: any;

    get plannerTaskRes() {
      return programPlanner.ProgPlanTaskRes;
    }

    get getUserDetail() {
      return commonEntry.userDetail;
    }


    beforeMount() {
 
      const user_id = APP_UTILITIES.getCookie(APP_CONST.USER_ID);
      if (user_id) {
        commonEntry.fetchUserDetails(parseInt(user_id));
      }
      this.currentRoleId = this.userDetails.roleId;
      this.currentSiteId = this.userDetails.siteId;
      this.plannerTaskObj.programId = this.userDetails.programId;
      this.plannerTaskObj.siteId = (this.currentRoleId === 6 || this.currentRoleId === 1 || this.currentRoleId === 2)
        ? this.currentSiteId
        : 0;
      this.plannerTaskObj.programStartDate = this.userDetails.startDate;
      this.plannerTaskObj.programEndDate = this.userDetails.endDate;
    }


    public getScreenText(key: string): string {
      return this.objScreenText.getScreenText(key);
    }



    public getRoleId() {
      const pID = 0;
      const userRoles: any = APP_UTILITIES.getCookie('user_role');
      this.userRoles = JSON.parse(userRoles);
      this.userRoles.forEach((item: any, index: number) => {
        if (item.hasOwnProperty('roleId')) {
          this.currentRoleId = item.roleId;
        }
            
      });
      return this.programId = pID;
    }

    public getSiteId() {
      const userRoles: any = APP_UTILITIES.getCookie('user_role');
      this.userRoles = JSON.parse(userRoles);
      this.userRoles.forEach((item: any, index: number) => {
        if (item.hasOwnProperty('siteId')) {
          this.currentSiteId = item.siteId;
        }
        else{
          const siteId: any = APP_UTILITIES.getCookie('siteId');
          this.currentSiteId = Number(siteId);
        }
      });
    }

 
    @Watch(APP_CONST.GET_USER_DT, { deep: true })
    getProgId(data: any) {
    
    }

    addProgramTask() {
      this.programSubmitCount = this.programSubmitCount + APP_CONST.ONE;
      this.isTaskNameError=  false;
      const ObjPlannerTask:any = { ...this.plannerTaskObj };
      const userDetails:any = commonEntry.userDetail;
      ObjPlannerTask['userRoles'] = userDetails.userRoles;
      const ValidateObj = this.validatedFields;
      const checkValidation = APP_UTILITIES.requiredFields(ObjPlannerTask, ValidateObj);
      this.validatedFields = { ...checkValidation.validateData };
      if (checkValidation.isFormInvalid) {
        this.programSubmitCount =APP_CONST.ZERO;
        this.allFieldRequired = checkValidation.isFormInvalid;
        return; 
      }
      ObjPlannerTask.startDate = APP_UTILITIES.formatCorrectDate(ObjPlannerTask.startDate) as any;
      ObjPlannerTask.endDate = APP_UTILITIES.formatCorrectDate(ObjPlannerTask.endDate) as any;
      if(this.programSubmitCount === APP_CONST.ONE) {
        programPlanner.addProgramPlanner(ObjPlannerTask).then((createdTask: any) => {
          if(createdTask.status === APP_CONST.RESPONSE_200) {
            this.programSubmitCount =APP_CONST.ZERO;
            ObjPlannerTask.id = programPlanner.ProgPlanTaskRes.data.id;
            this.$emit(APP_CONST.OPEN_POPUP, createdTask.data);
            const programStartDate = this.plannerTaskObj.programStartDate;
            const programEndDate = this.plannerTaskObj.programEndDate;
            this.plannerTaskObj = { ...APP_CONST.PROGRAM_PLANNER } as any;
            this.plannerTaskObj.programStartDate = programStartDate;
            this.plannerTaskObj.programEndDate = programEndDate;
            this.plannerTaskObj.programId = ObjPlannerTask.programId;
            this.plannerTaskObj.siteId = (this.currentRoleId === 6 || this.currentRoleId === 1 || this.currentRoleId === 2)
              ? this.currentSiteId
              : 0;
            this.allFieldRequired = APP_CONST.FALSE;                    
            const scroll: any = document.getElementById('offcanvas-flip-programs-scroll');
            scroll.scrollTop = 0;
          }
          else if(createdTask.status === APP_CONST.RESPONSE_400){
            if(createdTask.data.errors.title[0]) {
              this.isTaskNameError=  true;
            }
            this.programSubmitCount =APP_CONST.ZERO;
          }
          else {
            this.programSubmitCount =APP_CONST.ZERO;
            const scroll: any = document.getElementById('offcanvas-flip-programs-scroll');
            scroll.scrollTop = 0;
            this.refillForm();
          }
        });
      }        
    }

    checkForCorrectDate(type: string) {
      this.dateType = '';
      this.dateResponseError =APP_CONST.FALSE;
      let startDate = this.plannerTaskObj.startDate;
      let endDate = this.plannerTaskObj.endDate;
      if (type === APP_CONST.END_DATE) {
        if (startDate.length === APP_CONST.ZERO) {
          this.dateResponseError =APP_CONST.TRUE;
          this.dateResponse = APP_CONST.FILL_START;
          this.allFieldRequired = APP_CONST.FALSE;
          this.dateType = 'startDate';
          setTimeout(() => {
            endDate = '';
            startDate = '';
            this.plannerTaskObj.startDate = '';
            this.plannerTaskObj.endDate = '';
          });
        }
        if (Date.parse(this.plannerTaskObj.endDate) < Date.parse(this.plannerTaskObj.startDate)) {
          this.dateResponseError =APP_CONST.TRUE;
          this.dateResponse = APP_CONST.ALERT_END_DATE;
          this.allFieldRequired = APP_CONST.FALSE;
          this.dateType = type;
          setTimeout(() => {
            endDate = ''; this.plannerTaskObj.endDate = ''; 
          });
        }
      }
      else {
        if (Date.parse(this.plannerTaskObj.endDate) < Date.parse(this.plannerTaskObj.startDate)) {
          this.dateResponseError =APP_CONST.TRUE;
          this.dateResponse = APP_CONST.ALERT_END_DATE;
          this.allFieldRequired = APP_CONST.FALSE;
          this.dateType = 'endDate';
          setTimeout(() => {
            endDate = ''; this.plannerTaskObj.endDate = ''; 
          });
        } 
      }
      const programStartDate = new Date(Date.parse(this.plannerTaskObj.programStartDate));
      const programEndDate = new Date(Date.parse(this.plannerTaskObj.programEndDate));
     
    }

    refillForm() {
      const obj = { programId: this.plannerTaskObj.programId, programStartDate: this.plannerTaskObj.programStartDate, programEndDate: this.plannerTaskObj.programEndDate };
      this.plannerTaskObj = { ...APP_CONST.PROGRAM_PLANNER } as IProgramPlanner;
      this.plannerTaskObj.programId = obj.programId,
      this.programSubmitCount = APP_CONST.ZERO;
      this.plannerTaskObj.programStartDate = obj.programStartDate,
      this.plannerTaskObj.programEndDate = obj.programEndDate;
      this.plannerTaskObj.siteId = (this.currentRoleId === 6 || this.currentRoleId === 1 || this.currentRoleId === 2)
        ? this.currentSiteId
        : 0;
      const scroll: any = document.getElementById('offcanvas-flip-programs-scroll');
      scroll.scrollTop = 0;
      this.isTaskNameError=  false;
    }

    openEvent() {
      this.refillForm();
      this.$emit(APP_CONST.OPEN_EVENT);
    }

    back() {
      this.allFieldRequired = APP_CONST.FALSE;
      this.refillForm();
      this.$emit(APP_CONST.BACK_BUTTON);
    }
}